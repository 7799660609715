import React, { PureComponent } from 'react';
import toastIconError from '../../img/icon_error.svg';

export default class IconErrorToast extends PureComponent {
  render() {
    return (
      <div className="contentAvatarToastr">
        {/* <img src={toastIconError} alt="avatar" className="icon_error" /> */}
      </div>
    );
  }
}
