//@flow
import { urlsConstants } from '../constants/url.constant';
import { defaultService } from './defaultService';
import axios from "axios";

export const authenticationService = {
  doLogin,
  doLogout,
  hasToken,
  cadastroSimples,
  validate,
};

function doLogin(username: string, password: string) {
  const clientId: string = process.env.REACT_APP_CLIENT_ID || '';
  const clientPassword: string = process.env.REACT_APP_CLIENT_PASSWORD || '';

  const details = {
    client_id: clientId,
    grant_type: 'password',
    username,
    password
  };

  const formBody = Object.keys(details).map(
    key => encodeURIComponent(key) + '=' + encodeURIComponent(details[key])
  );

  const basicAuthorization = btoa(clientId + ':' + clientPassword);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + basicAuthorization
    },
    body: formBody.join('&')
  };

  return fetch(urlsConstants.OAUTH_TOKEN, requestOptions).then(response => {
    if (!response.ok) {
      return Promise.reject(response.statusText);
    }
    console.log(response);
    return response.json();
  }).then(token => {
    if (token) {
      localStorage.setItem('token', JSON.stringify(token));
    }
    return token;
  });
}

function hasToken() {
  return localStorage.getItem('token') !== null;
}

function validate(dados) {
  console.log('me ok')

  return axios.post(urlsConstants.PESSOA_VALID_STEP_ONE, dados);
}
function cadastroSimples(dados) {
  console.log('dados');
  console.log(dados);
  return axios.post(urlsConstants.CADASTRO_SIMPLES, dados);
}

function doLogout() {
  localStorage.clear();
}
