import {urlsConstants} from '../constants/url.constant';
import {defaultService} from './defaultService';


export const pedidosService = {

    findByFiltro,
    findAll,
    nextNumber,
    doSave
    ,doGet,
    findEstoque,
    validaEdicao,
    validaConclusao
};


function validaConclusao(id){
    return defaultService.doGet(`${urlsConstants.API_PEDIDOS}concluido/${id}`)
}

function validaEdicao(id){
    return defaultService.doGet(`${urlsConstants.API_PEDIDOS}viewMode/${id}`)
}

function findEstoque(produto){
    return defaultService.doGet(`${urlsConstants.API_PEDIDOS}getEstoque/${produto.codigoOmni}`)
}

function findAll(){
    return defaultService.doGet(`${urlsConstants.API_PEDIDOS}findAll`)
}
function nextNumber(){
    return defaultService.doGet(`${urlsConstants.API_PEDIDOS}next`)
}
function findByFiltro(
    options: Options,
    filter: any
): Promise<Array<>> {
    console.log(filter)
    if(filter&&filter.cliente&&filter.cliente.codigoCliente) {
        return defaultService.doGet(
            `${urlsConstants.PEDIDOS}/search/findByCliente?page=${options.page - 1}&cliente=${filter.cliente.codigoCliente}&sort=numeroPedido,desc`,
            options,
            filter
        );
    }
    filter.page = options.page-1;
    if(filter.dashboard) {
        return defaultService.doPost(
            `${urlsConstants.API_PEDIDOS}findByFiltro`,
            filter
        );

    }else{
        return defaultService.doPost(
            `${urlsConstants.API_PEDIDOS}findByFiltroAdmin`,
            filter
        );
    }



}

function doSave(usuario: usuario) {
    if(!usuario.id) {
        return defaultService.doSave(urlsConstants.PEDIDOS, usuario);
    }else{
        return defaultService.doSave(`${urlsConstants.PEDIDOS}/`, usuario);
    }
}
function doGet(id) {
    return defaultService.doGet(`${urlsConstants.PEDIDOS}/${id}`);
}