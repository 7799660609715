import * as React from "react";
import { Formik } from 'formik';
import _ from 'lodash';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import FormSelectInput from '../../components/Inputs/FormSelectInput';
import SelectInput from '../../components/Inputs/SelectInput';
import Toast from '../../components/Toasts/Toast';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import {userService} from "../../services/user.service";
import FormInput from "../../components/Inputs/FormInput";
import CpfCnpjInput from "../../components/Inputs/CpfCnpjInput";
import {defaultService} from "../../services/defaultService";
import {objectsConstants} from "../../constants/objects.constants";
import {produtoService} from "../../services/produto.service"
import {clienteService} from "../../services/cliente.service";
import {pedidosService} from "../../services/pedidos.service";
import moment from 'moment';
import CadastroPedidoModal from "../../components/Modals/CadastroPedidoModal";
import FileInput from "../../components/Inputs/FileInput";
import {notasService} from "../../services/notas.service";
import DadosNota from "./DadosNota";
;

const PEDIDO_VALIDATE = Yup.object().shape({

    itens: Yup.array().of(
        Yup.object().shape({
            produto:
                Yup.object().shape({
                    id: Yup.string().required("Obrigatório")


                })
        }),

)});


class NotasEdit extends React.PureComponent<Props> {

constructor(props){
    super(props)
    this.state={entity:{},viewMode:false,index:null};
}

    componentDidMount() {
        console.log(this.props)
        if (this.props.match.params.id) {
            this.props.loading(true);
            pedidosService.doGet(this.props.match.params.id).then(
                (response) => {
                    console.log(response);
                    let pedido = response.data;
                    console.log(pedido);
                    pedidosService.validaEdicao(this.props.match.params.id).then(responseViewMode=>{
                        this.setState({viewMode:responseViewMode.data},()=>{
                    if(pedido.itens&&pedido.itens.length>0) {
                        let produtos = _.uniqBy(pedido.itens, 'produto.id');



                        Promise.all(_.map(produtos, (i) => {
                            return pedidosService.findEstoque(i.produto);
                        })).then(allResponse => {
                            let itens = [];
                            _.map(allResponse,(response,index)=>{
                                let estoque = _.map(response.data,(e)=>{
                                    _.map(pedido.itens,(item)=>{
                                        if(item.observacoes==e.lote){
                                            e.quantidade = item.quantidade;
                                        }
                                    });
                                    return e;
                                });
                                console.log(estoque);
                                let produto = produtos[index].produto;
                                produto.estoque = estoque;
                                itens.push( {produto});
                            })
                            pedido.itens = itens;
                            console.log(pedido);
                            this.props.loading(false);
                            this.setState({entity: pedido})
                        }
                            ,(errorEstoque)=>{
                                console.log(errorEstoque)
                                this.props.loading(true);
                            }

                        );
                    }else {
                        this.setState({entity: pedido}, () => {
                            this.props.loading(false);
                        });
                    }
                        })
                    },(errorViewMode)=>{
                        console.log(errorViewMode)
                        this.props.loading(true);
                    })
                },
                (error) => {
                    console.log('error');
                    console.log(error);
                    this.props.loading(false);
                }
          );
        }else{

                this.setState({entity:{cliente:this.props.user.cliente}});

        }
    }

    showError = messageError => {
        this.props.error({ message: messageError });
        return '';
    };

    showInfo = messageInfo => {
        this.props.info({ message: messageInfo });
        return '';
    };

    clearError = () => {
        this.props.clear();
        return '';
    };

    render() {
        const { entity } = this.state;
       let _this = this;
        return (
            <React.Fragment>
            <TopoTitleComponente mainTitle="Notas" subTitle=" " canBack={true} />
        <Formik
        validationSchema={PEDIDO_VALIDATE}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={entity}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
            this.props.loading(true)
            notasService.save(values).then(r=>{

                this.props.history.push('/notas');


                actions.setSubmitting(false);
                this.props.loading(false)
                this.props.success({ message: 'Nota enviada com sucesso.' });
            })
        }}
    >
        {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue
          }) => (
            <form onSubmit={handleSubmit} noValidate>
        <div className="flex-column p-5">
            <Toast />

            {!_.isEmpty(errors) &&
        this.showError(objectsConstants.messageCampoObrigatorio)}

            {values.id }
        <div className="cardSeculus d-flex flex-column mb-0">
            <div className="topoCard d-flex flex-column flex-sm-row">
            <h3 className="flex-grow-1">Notas</h3>
            </div>

            <div className="form-row">
                <FileInput
                    emptyLabel="Importar Nota Fiscal"
                    name="Nota Fiscal"
                    entity={values}
                    fileItem={values.nota}
                    onChange={(name,value)=>{

                        if(value&&value.data){
                            notasService.importarNota(value).then(r=>{
                               setFieldValue('notaImportada',r.data)
                                setFieldValue('nota',value)
                            })
                        }


                    setFieldValue(name,value);}}
                    className={`${this.state.viewMode || values.laudoTecnico ? '' : 'btn btn-secondary'}`}
                    readOnly={this.state.viewMode}
                    />


                {values.notaImportada&&(
                   <DadosNota infNFe={values.notaImportada.nfe.infNFe} setFieldValue={setFieldValue} values={values}loading={this.props.loading}/>
                )}



        </div>
            {!this.state.viewMode&&(
        <div className="d-flex flex-row flex-sm-row ">
            <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
            <button
            type="button"
            className="btn btn-secondary btn30 white"
            disabled={isSubmitting}
            onClick={() => this.props.history.goBack()}
        >
            Cancelar
            </button>
            </div>
            <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
            <button
            type="submit"
            //isabled={isSubmitting||!values.itens||values.itens.length==0}
            className="btn btn-secondary btn30 green"

                >
                Enviar pedido
                </button>
                </div>
                </div>
            )}
                </div>

                </div>
                </form>
        )}
    </Formik>
        </React.Fragment>
    );
    }
}


function mapStateToProps(state) {
    const { user } = state.authentication;

    return {
        user,

    };
}

const mapDispatch = ({
                         alert: { success, error, clear },
                         load: { loading },
                     }) => ({
    success: (msg) => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: (msg) => error(msg),
    clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
    forwardRef: true,
})(NotasEdit)