import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {translate} from "../../../helpers/message.helper";
import {ToolTipFeedBack} from "../../Utils/ToolTipFeedBack";

class FormField extends Component {

    // initialize state
    state = { value: '', dirty: false, errors: [] }

    hasChanged = e => {
        e.preventDefault();

        // destructure props - assign default dummy functions to validator and onStateChanged props
        const { label, required = false, validator = f => f, onStateChanged = f => f } = this.props;

        const value = e.target.value;
        const isEmpty = value.length === 0;
        const requiredMissing = this.state.dirty && required && isEmpty;

        let errors = [];


        console.log('messageValidate')

        if (requiredMissing) {
            // if required and is empty, add required error to state
            errors = [...errors, `${label} is required`];
        } else if ('function' === typeof validator) {
            try {
                validator(value);
            } catch (e) {
                // if validator throws error, add validation error to state
                errors = [...errors, e.message];
            }
        }



        // update state and call the onStateChanged callback fn after the update
        // dirty is only changed to true and remains true on and after the first state update
        this.setState(({ dirty = false }) => ({ value, errors, dirty: !dirty || dirty }), () => onStateChanged(this.state));
    }

    render() {
        let { value, dirty, errors } = this.state;
        const { name, type, label, fieldId, placeholder, children ,messages} = this.props;

        if (messages != null && messages.length > 0) {
            messages.forEach(function (message, index) {
                if (message.fieldName == fieldId) {
                    errors =[...errors,translate(message.message.code)];
                }
            });
        }

        console.log('hasErrors',hasErrors)

        const hasErrors = errors.length > 0;
        const controlClass = ['form-control index_input',  hasErrors ? 'is-invalid' : ''].join(' ').trim();

        return (
            <Fragment>

                <div className="form-group">
                    <label htmlFor={fieldId}>{label}</label>
                    {/** Render the first error if there are any errors **/}
                    {/* {hasErrors && <div className="error form-hint font-weight-bold text-right m-0 mb-2">{errors[0]}</div>} */}

                    {/** Render the children nodes passed to component **/}

                    <input name={name} type={type} className={controlClass} id={fieldId} placeholder={placeholder} value={value} onChange={this.hasChanged} />
                    {children}
                </div>
                {errors.length>0 && (
                    <ToolTipFeedBack
                        target={fieldId}
                        messageValidate={errors.join(', ')}
                    />
                )}
            </Fragment>
        );
    }

}

FormField.propTypes = {
    type: PropTypes.oneOf(["text", "password"]).isRequired,
    label: PropTypes.string.isRequired,
    fieldId: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    required: PropTypes.bool,
    children: PropTypes.node,
    validator: PropTypes.func,
    onStateChanged: PropTypes.func
};

export default FormField;