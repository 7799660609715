//@flow
import * as React from 'react';

type Props = { onChangeText: any, entity: any, onChangeValue: any };

export default class UsuarioCrudListFilter extends React.PureComponent<Props> {
  render() {
    const { entity } = this.props;
    return (
      <React.Fragment>

      </React.Fragment>
    );
  }
}