import { defaultService } from './defaultService';
import { urlsConstants } from '../constants/url.constant';

export const auditLogService = {
    getAuditLogsByEntityId
};

function getAuditLogsByEntityId(entityId) {
    return defaultService.doGet(`${urlsConstants.API_AUDITLOG}/by-entity-id/${entityId}`);
}
