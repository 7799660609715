//@flow
import moment from "moment";
import { objectsConstants } from "../constants/objects.constants";

type Options = {
  pattern?: string,
  mode?: string
};

const DATE_TIME = "DD/MM/YYYY HH:mm";
const DATE = "DD/MM/YYYY";
const TIME = "HH:mm:ss";

const TIME_MODE = "TIME";
const DATE_MODE = "DATE";

export const dateHelper = {
  format,
  getDiasDaSemana,
  now,
  isAfterNow,
  diff,
  currentMonthName,
  currentYear,
  monthName,
  getFirstDayLastMonth,
  getLastDayLastMonth,
  getDateParteFromString
};

function getDateParteFromString(value, mode) {
  if(!value)
  {
    return '--'
  }
  let array = value.split(" ");
  if (mode === DATE_MODE) {
    return array[0];
  }else{
    return array[1];
  }
}

let pickerLang = {
  months: objectsConstants.MONTH_ARRAY
};
function monthName(month) {
  return pickerLang.months[month];
}
function currentMonthName() {
  return pickerLang.months[moment().month()];
}
function currentYear() {
  return moment().year();
}

function isAfterNow(value: string | Date) {
  return moment(now()).isBefore(value);
}

function getFirstDayLastMonth() {
  let lastDayLastMonth = moment().date(0);
  return lastDayLastMonth.date(1);
}
function getLastDayLastMonth() {
  return moment().date(0);
}
function diff(startdDate, endData) {
  if (!endData) {
    endData = moment().format();
  } else {
    endData = moment(endData).format();
  }
  return Math.round(moment(endData).diff(startdDate, "days", true));
}

function now() {
  return moment().format();
}

function format(value: string | Date, options?: Options) {
  let format = DATE_TIME;

  if (value === undefined) {
    return "--";
  }

  if (options) {
    if (options.pattern) {
      format = options.pattern;
    } else {
      const { mode } = options;
      if (DATE_MODE === mode) {
        format = DATE;
      } else if (TIME_MODE === mode) {
        format = TIME;
      }
    }
  }

  let date = moment(value);
  return date.format(format);
}

function getDiasDaSemana() {
  let arrayDays = [];

  arrayDays.push({ id: 1, nome: "Seg" });
  arrayDays.push({ id: 2, nome: "Ter" });
  arrayDays.push({ id: 3, nome: "Qua" });
  arrayDays.push({ id: 4, nome: "Qui" });
  arrayDays.push({ id: 5, nome: "Sex" });
  arrayDays.push({ id: 6, nome: "Sáb" });
  arrayDays.push({ id: 0, nome: "Dom" });

  return arrayDays;
}
