export const propertyConstantes = {

  ABERTURA_DE_OS: 'ABERTURA_DE_OS',
  EM_CRIACAO: 'EM_CRIACAO',
  APROVAR_ORCAMENTO: 'APROVAR_ORCAMENTO',
  ORCAMENTO_REPROVADO: 'ORCAMENTO_REPROVADO',
  ORCAMENTO_APROVADO: 'ORCAMENTO_APROVADO',
  REPARADA: 'REPARADA',
  ENTREGUE: 'ENTREGUE',
  AVALIACAO_TECNICA: 'AVALIACAO_TECNICA',
  PRE_AVALIACAO_TECNICA: 'PRE_AVALIACAO_TECNICA',
  AGUARDANDO_CONSERTO: 'AGUARDANDO_CONSERTO',
  CONSERTADA: 'CONSERTADA',
  CONTROLE_QUALIDADE: "CONTROLE_QUALIDADE",
  FINALIZADA: 'FINALIZADA',
  CANCELADA: 'CANCELADA',
  TODOS: 'TODOS',
  OS: 'OS',
  PA: 'PA',
  NOVO: 'NOVO',
  ATENDIDO: 'ATENDIDO',
  NAO_ATENDIDO: 'NAO_ATENDIDO',
  PENDENTE: 'PENDENTE',
  FINALIZADO: 'FINALIZADO',
  CELULAR: 'Celular',
  RESIDENCIAL: 'Residencial',
  COMERCIAL: 'Comercial',
  CELULAR_WHATSAPP: 'Celular / WhatsApp',
  AGUARDANDO_RELOGIO: 'AGUARDANDO_RELOGIO',
  AGUARDANDO_RELOGIO_DE_TROCA: 'AGUARDANDO_RELOGIO_DE_TROCA',
  TROCA_MODELO: 'TROCA_MODELO',
  APROVADO_QC: 'APROVADO_QC',
  REPROVADO_QC: 'REPROVADO_QC',
  ATIVO: 'ATIVO',
  INATIVO: 'INATIVO',
  ESCOLHA_DE_MODELO: 'ESCOLHA_DE_MODELO',
  ATENDIDA: 'ATENDIDA',
  MAQUINA: 'MAQUINA',
  MODULO: 'MODULO',
  TROCA_NAO_AUTORIZADA: 'TROCA_NAO_AUTORIZADA',
  TROCA_REVERTIDA: 'TROCA_REVERTIDA',
  SEGUNDA_A_SEXTA: 'Segunda a Sexta',
  SEGUNDA_A_SABADO: 'Segunda a Sábado'
};
