import {urlsConstants} from '../constants/url.constant';
import {defaultService} from './defaultService';


export const produtoService = {

    findAllByCnpjAndCodigo,
    findAll

};
async function  findAllByCnpjAndCodigo(produtos,cnpj){
    let retorno = [];
    for(let i in produtos){
        let produto = produtos[i];
        let produtoDB = await defaultService.doGet(`${urlsConstants.API_PRODUTO}findByClienteAndCodigo/${cnpj}/${produto.prod.cprod}`)
        retorno.push(produtoDB.data);
    }
  return retorno;
}
function findAll(parent){
    console.log(parent);

    if(parent){
        return defaultService.doGet(`${urlsConstants.API_PRODUTO}findByCodigoDepositante/${parent.codigoDepositante}`)
    }
    return defaultService.doGet(`${urlsConstants.API_PRODUTO}findAll`)
}

