//@flow

const URL_BASE: string = process.env.REACT_APP_CLIENT_URL_API || '';
const CONTEXT_PATH: string = process.env.REACT_APP_CONTEXT_PATH || '';
const URL = URL_BASE;

export const urlsConstants = {
  URL_DOMAIN: URL,
  CONTEXT_PATH,
  OAUTH_TOKEN: URL_BASE + '/oauth/token',
  POSTO_AUTORIZADO: `${URL}/postoAutorizado/`,
  POSTO_BY_DOCUMENTO: `${URL}/postoAutorizado/documento/`,
  CADASTRO_SIMPLES: `${URL}/public/pessoa/`,
  POSTO_AUTORIZADO_REGISTER: `${URL}/public/postoAutorizado/`,
  PESSOA_VALID_STEP_ONE:
    URL_BASE + '/public/pessoa/validate/etapa1',
  POSTO_AUTORIZADO_VALID_STEP_TWO:
    URL_BASE + '/public/postoAutorizado/validate/etapa2',
  FIND_PAIS: URL_BASE + '/public/endereco/pais/findall/',
  FIND_ESTADO: URL_BASE + '/public/endereco/estado/findByPais/',
  FIND_ESTADO_EXACT: URL_BASE + '/public/endereco/estado/findExact/',
  FIND_CIDADE_EXACT: URL_BASE + '/public/endereco/cidade/findExact/',
  FIND_CIDADE: URL_BASE + '/public/endereco/cidade/findByEstado/',
  FIND_PRODUTO: `${URL}/produto/find/`,
  PRODUTO: `${URL}/produto/`,
  MODELO_PRODUTO: `${URL}/modeloProduto/`,
  FIND_PRODUTO_BY_MARCA: `${URL}/produto/findByMarca/`,
  TIPO_EQUIPAMENTO: `${URL}/tipoEquipamento/`,
  USER_ME: `${URL_BASE}/user/me`,
  HANDLE_TASK: `${URL_BASE}/task/`,
  POSTO_AUTORIZADO_FAIXADA: `${URL}/postoAutorizado/save/fotoFaixada/`,
  POSTO_AUTORIZADO_INTERNO: `${URL}/postoAutorizado/save/fotoInterna/`,
  POSTO_AUTORIZADO_CONTRATO_SOCIAL: `${URL}/postoAutorizado/save/contratoSocial/`,
  POSTO_HABILITADO: `${URL}/postoHabilitado/`,
  FIND_POSTO_HABILITADO: `${URL}/postoHabilitado/find/`,
  MIDIA_IMAGE: URL_BASE + '/public/midia/imagem/',
  MIDIA_FILE: URL_BASE + '/public/midia/file/',
  CLIENTE_BY_DOCUMENTO: `${URL}/cliente/documento/`,
  CLIENT_BASE: `${URL}/cliente/`,
  TASK_FIND_LAST: `${URL}/tarefas/findLast/`,
  WS_WEBSOCKET: URL_BASE + '/ws',
  WS_MESSAGE_OS: URL_BASE + '/chat/os/',
  POSTO_AUTORIZADO_AUTORIZAR: `${URL}/postoAutorizado/save/autorizar/`,
  GET_RESPONSAVEL_LEGAL: `${URL}/postoAutorizado/findResponsavelLegal/`,
  OS_TOTAL_MES: `${URL}/ordemServico/totalMes/`,
  FIND_OS: `${URL}/ordemServico/find/`,
  FIND_OS_BY_ID: `${URL}/ordemServico/findOS/`,
  ORDEM_SERVICO: `${URL}/ordemServico/`,
  PUBLIC_ORDEM_SERVICO: `${URL}/public/ordemServico/`,
  COMPRA_PECA: `${URL}/compraPeca/`,
  FIND_MARCA: `${URL}/marca/find/`,
  FIND_POSTO_MARCA: `${URL}/marca/`,
  FIND_RELOGIO: `${URL}/relogio/find/`,
  RELOGIO: `${URL}/relogio/`,
  ORDEM_SERVICO_FIND_BY_CLIENTE: `${URL}/ordemServico/findByCliente/`,
  ORDEM_SERVICO_SAVE_TROCA_RELOGIO: `${URL}/ordemServico/saveTrocaRelogio/`,
  ORDEM_SERVICO_FIND_CHECKLIST: `${URL}/grupoTopicosCheckList/findCheckList/`,
  PUBLIC_USUARIO: `${URL}/public/user/`,
  USER: `${URL}/user/`,
  GRUPO_MARCA: `${URL}/grupoMarca/`,
  FIND_USER: `${URL}/user/find/`,
  FIND_GRUPO_MARCA: `${URL}/grupoMarca/find/`,
  FIND_PECA: `${URL}/peca/find/`,
  FIND_ALL_CAUSA: `${URL}/causaOs/findAll`,
  FIND_ALL_SINTOMA: `${URL}/sintomaOs/findAll`,
  FIND_ALL_SERVICO: `${URL}/servico/findAll`,
  ORDEM_SERVICO_ORCAMENTO: `${URL}/ordemServico/saveOrcamento/`,
  ORDEM_SERVICO_PRE_AVALIACAO: `${URL}/ordemServico/preAvaliacao/`,
  ORDEM_SERVICO_REPROVAR_ORCAMENTO: `${URL}/ordemServico/reprovarOrcamento/`,
  ORDEM_SERVICO_APROVAR_ORCAMENTO: `${URL}/ordemServico/aprovarOrcamento/`,
  ORDEM_SERVICO_REPROVAR_CONSERTO: `${URL}/ordemServico/reprovarConserto/`,
  ORDEM_SERVICO_APROVAR_CONSERTO: `${URL}/ordemServico/aprovarConserto/`,
  ORDEM_SERVICO_COLOCAR_EM_AVALIACAO: `${URL}/ordemServico/reabrirOrcamento/`,
  ORDEM_SERVICO_REABRIR_OS: `${URL}/ordemServico/reabrirOS/`,
  ORDEM_SERVICO_COLOCAR_TROCA_MODELO: `${URL}/ordemServico/reabrirTrocaModelo/`,
  ORDEM_ENTREGA_PRODUTO_OS: `${URL}/ordemServico/entregaProduto/`,
  ORDEM_ENTREGA_OS_REPARADA: `${URL}/ordemServico/osReparada/`,
  TROCA_MAIS_MODELOS: `${URL}/ordemServico/trocaMaisModelos/`,
  DOC: `${URL}/doc/`,
  PECA: `${URL}/peca/`,
  FIND_TIPO_TRANSPORTE: `${URL}/tipoTransporte/findAll`,
  PLANO_PAGAMENTO: `${URL}/planoValores/`,
  PAGAMENTO: `${URL}/postoAutorizadoPagamento/`,
  FINANCEIRO: `${URL}/financeiro/`,
  OS_GET_USERS: URL_BASE + '/ordemServico/getUsers/',
  MIDIA_IMAGE_JSON: URL_BASE + '/public/midia/imagemJson/',
  FIND_MES: `${URL}/data/mes/findall`,
  FIND_ANO: `${URL}/data/ano/findall`,
  HISTORICO_OS: `${URL}/ordemServico/historico/`,
  FIND_MODELO: `${URL}/marca/findModelos/`,
  RELATORIO_REPRESAMENTO_PRE_REPARO: `${URL}/relatorio/represamentoPreReparo/`,
  RELATORIO_PRAZO_MEDIO_ATENDIMENTO_REPARO: `${URL}/relatorio/prazoMedioAtendimentoReparo/`,
  RELATORIO_PRAZO_MEDIO_ENTREGA_POS_REPARO: `${URL}/relatorio/prazoMedioEntregaPosReparo/`,
  RELATORIO_VOLUME_POR_STATUS: `${URL}/relatorio/volumePorStatus/`,
  RELATORIO: `${URL}/relatorio/`,
  UPDATE_VIEW_RECEIVER_OS: `${URL}/chat/updateReceiverOS/`,
  UPDATE_VIEW_RECEIVER: `${URL}/chat/updateReceiver/`,
  FIND_MY_MESSAGE: `${URL}/mymessage`,
  FIND_CONTACT: `${URL}/contacts`,
  AVALIAR_ATENDIMENTO: `${URL}/public/avaliarAtendimento/`,
  ORDEM_SERVICO_SAVE_MODELOS_TROCA_RELOGIO: `${URL}/ordemServico/saveModelosTrocaRelogio/`,
  POSTO_VALOR_ATENDIMENTO_GARANTIA: `${URL}/postoValorAtendimentoGarantia/`,
  HISTORICO_USER: `${URL}/user/historico/`,
  FIND_ALL_MOTIVO_REJEICAO: `${URL}/motivoRejeicao/findAll`,
  RELATORIO_DISPONIBILIDADE_PECAS_EPR: `${URL}/relatorio/disponibilidadePecasEpr/`,
  RELATORIO_OS_EXPIRADA: `${URL}/relatorio/osExpirada/`,
  TROCA_MODELO: `${URL}/trocaModelo/`,
  GET_POSTO_OS: `${URL}/ordemServico/getPosto/`,
  GET_TOTAL_FINANCEIRO: `${URL}/ordemServico/getTotalFinanceiro/`,
  FIND_SINTOMA_BY_PECA: `${URL}/sintomaOs/findByPeca`,
  FIND_CAUSA_BY_SINTOMA: `${URL}/causaOs/findBySintoma`,
  TECNICO: `${URL}/tecnico/`,
  MARCA: `${URL}/marca/`,
  FIND_ALL_GRUPO_MARCA: `${URL}/grupoMarca/findAll`,
  FIND_PERFIL: `${URL}/perfil/find/`,
  PERFIL: `${URL}/perfil/`,
  PERMISSOES_PERFIL: `${URL}/permissoesPerfil/`,
  PAGAMENTO_PUBLIC: `${URL}/public/postoAutorizadoPagamento/`,
  CATEGORIA_ASSISTENCIA: `${URL}/categoriaAssistencia/`,

  CONFIGURACOES:`${URL}/configuracoes/`,

  CLIENTE:`${URL}/cliente/`,
  USUARIO:`${URL}/usuario/`,
  API_CLIENTE:`${URL}/api/cliente/`,
PEDIDOS:`${URL}/pedidos`,
  API_PRODUTO:`${URL}/api/produto/`,
  API_NFS:`${URL}/api/nfse/`,
  API_PEDIDOS:`${URL}/api/pedidos/`,
    API_AUDITLOG: `${URL}/api/auditlogs`,
};
