//exemplo de chamada do modal
// type State = {
//   isModalVisible: boolean
// };
// this.state = {
//   isModalVisible: false
// };
// <NovaOsModal
//   isModalVisible={this.state.isModalVisible}
//   togglemodal={this._togglemodal}
// />;
// _togglemodal = () => {
//   this.setState({ isModalVisible: !this.state.isModalVisible });
// };
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Modal, ModalBody} from 'reactstrap';
import * as Yup from 'yup';
import moment from 'moment';
import './CadastroPedidoModal.css';
import _ from "lodash";

type State = {
  isModalVisible: any
};

const NewCompraInit = Yup.object().shape({
  documentoModal: Yup.string()
    .min(14, 'CPF ou CNPJ inválido!')
    .max(18, 'CPF ou CNPJ inválido!')
    .required('CPF ou CNPJ é obrigatório')
});

class CadastroPedidoModal extends PureComponent<{}, State> {
  constructor(props) {
    super(props);
    this.state = {
      isModalVisible: false,
      produtos:[]
    };
  }

onChange=(estoque,quantidade)=>{
    let produtos = _.cloneDeep(this.state.produtos);
    let estoqueProduto = this.props.estoque;
    _.map(estoqueProduto,(e)=>{
        let produto = _.filter(produtos,{dataDeValidade:e.dataDeValidade,observacoes:e.lote});
      if(moment(e.dataDeValidade).isBefore(moment(estoque.dataDeValidade))&&(produto.length==0||e.quantidadeDisponivel>produto[0].quantidade)){
          this.props.info({ message: 'Lote não segue a regra FEFO'});
      }
    })
    let existeProduto = false;
  _.map(produtos,(produto)=>{

      if(produto.observacoes === estoque.lote&&produto.dataDeValidade===estoque.dataDeValidade) {
        produto.quantidade = quantidade;

        existeProduto = true;
      }


  })
 if (!existeProduto) {
   produtos.push({quantidade, observacoes: estoque.lote,dataDeValidade:estoque.dataDeValidade})
 }

 this.setState({produtos})
}
  toggleModal = () => {
    this.props.toggleModal();
  };

  salvarEstoque=()=>{


    this.props.selectEstoque(this.state.produtos);
    this.setState({produtos:[]})
  }

  render() {
    const { entity } = this.state;
    const {errors, handleChange,index} = this.props;
    return (
      <Modal size="lg" isOpen={this.props.isModalVisible} toggle={this.toggleModal} className={'modal-pedido'}>
        <div className="modal-header no-border justify-content-left">
          {/* <img src={iconChecklist} className="float-left mr-3" alt="icone"></img> */}
          <div className="mt-2 w-100">
            <div className="w-100 d-flex justify-content-end ">
              <button
                  type="submit"
                  className="btn btn-light mx-1"
                  disabled={false}
                  onClick={this.props.onCancel}
              >
                Cancelar
              </button>
              <button
                  type="button"
                  className="btn btn-primary mx-1"
                  disabled={false}
                  onClick={this.salvarEstoque}
              >
                Salvar
              </button>

            </div>
            <p><b>Selecione abaixo os Lotes e quantidades para solicitar o pedido:</b></p>
          </div>
          {/* <img src={iconFechar} className="pointer ml-auto" onClick={this.toggleModal} alt="fechar"></img> */}
        </div>

        <ModalBody>
          <div className="col d-flex flex-column corpo-modal">

            {/* bloco form Visualização */}




            {/* Serviços e acessórios */}
            <label className="txtCinza">Estoque</label>
            {/* Bloco form Visualização */}

            {/* Fim  bloco form Visualização */}
            {/* Inicio Botão add */}
            <div className="text-right pt-1 underline">
              {' '}
              <table className={'col-12 table'}>
                <tr>

                      <th>Quantidade Disponivel</th>

                  <th>Lote</th>
                  <th>Data de Validade</th>
                  <th>Quantidade</th>
                </tr>
                {this.props.estoque&&this.props.estoque.map((estoque,indexEstoque)=>{
                  if(!this.state.viewMode){
                    let produto = _.find(this.state.produtos,{observacoes:estoque.lote,dataDeValidade:estoque.dataDeValidade});
                    return ( <tr>
                      <td>{estoque.quantidadeDisponivel}</td>
                      <td>{estoque.lote}</td>
                      <td>{estoque.dataDeValidade&&moment(estoque.dataDeValidade).format('DD/MM/YYYY')}</td>
                      <td><div className="form-group col-12 col-lg-12 ">

                        <input
                            type="number"
                            className={`form-control 
                         `}
                            name={`itens[${index}].produto.estoque[${indexEstoque}].quantidade`}
                            placeholder="Digite a quantidade do produto"
                            value={produto&&produto.quantidade}
                            onChange={(e)=>{
                              let {name,value} = e.target;

                              this.onChange(estoque,value)
                            }}
                        />
                      </div></td>
                    </tr>)}else{
                    if(estoque.quantidade){
                      return ( <tr>

                        <td>{estoque.lote}</td>
                        {/*<td>{estoque.dataDeValidade&&moment(estoque.dataDeValidade).format('DD/MM/YYYY')}</td>*/}
                        <td>{estoque.quantidade}</td>
                      </tr>)
                    }
                  }
                })}

              </table>
            </div>

          </div>
        </ModalBody>
      </Modal >
    );
  }
}




export default connect(null, null)(withRouter(CadastroPedidoModal));
