//@flow
import React from 'react';
import _ from 'lodash';
import { dateHelper } from './date.helper';
import { colorConstants } from '../constants/color.constant';
import { objectsConstants } from '../constants/objects.constants';
import { ordemServicoHelper } from './ordemServico.helper';
import {formatterHelper} from "./formatter.helper";

export const tableHelper = {
  renderCustom
};

function renderCustom(row: any, extra: any, column: any) {
  let value = _.get(extra, column.path);
  if (column.isDateTime) {
    value = dateHelper.format(value);
  }
  if (column.isCpfCnpj) {
    value = formatterHelper.cpfCnpjFormatter(value);
  }
  if (column.isDate) {
    value = dateHelper.format(value, {mode: 'DATE'});
  }

  if (column.isDateTime) {
    value = dateHelper.format(value, {mode: 'DATE_TIME'});
  }


  if (column.isAddres) {
    if (value !== null && value !== undefined) {
      value =
        value.street + (', ' + value.number) + (' - ' + value.neighborhood);
    } else {
      value = '--';
    }
  }

  if (column.yesNo) {
    value = value ? 'Sim' : 'Não';
  }
  if (column.isMonth) {
    value = objectsConstants.MONTH_ARRAY[value - 1];
  }
  if (column.isMoney) {
    value = ordemServicoHelper.formatMoney(parseFloat(value), 2, ',', '.');
  }

  if (column.isDecimal) {
    value = parseFloat(value).toFixed(1);
  }

  if (column.isColor) {
    switch (value) {
      case colorConstants.VERDE_1.code:
        value = colorConstants.VERDE_1.color;
        break;
      case colorConstants.VERDE_2.code:
        value = colorConstants.VERDE_2.color;
        break;
      case colorConstants.AMARELO.code:
        value = colorConstants.AMARELO.color;
        break;
      case colorConstants.AZUL.code:
        value = colorConstants.AZUL.color;
        break;
      case colorConstants.LARANJA.code:
        value = colorConstants.LARANJA.color;
        break;
      case colorConstants.VERMELHO.code:
        value = colorConstants.VERMELHO.color;
        break;
      default:
        value = value;
    }
  }

  let customizedClass = '';
  if (column.hasClassName) {
    customizedClass = _.get(extra, column.hasClassName);
  }
  if (column.className) {
    customizedClass = column.className;
  }

  if (column.isStatus) {
    customizedClass = customizedClass + ' ' + _.get(extra, column.isStatus);
  }

  return (
    <div data-title={column.title} className={customizedClass}>
      {value ? value : '--'}
    </div>
  );
}
