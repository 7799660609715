//@flow
import _ from 'lodash';
import * as React from 'react';
import {TableHeaderColumn} from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import ListagemPage from '../../components/templates/ListagemPage';
import {tableHelper} from '../../helpers/tableHelper';
import UsuarioCrudListFilter from './UsuarioCrudListFilter';
import {objectsConstants} from '../../constants/objects.constants';
import {userService} from '../../services/user.service';
import TopoTitleComponente from "../Topo/TopoTitleComponente";
import {defaultService} from "../../services/defaultService";

const columns = [

  {
    path: 'nome',
    title: 'Nome',
    isKey: false,
    dataSort: false,
    className: 'txtBold'
  },
  {
    path: 'cpf',
    title: 'CPF',
    isKey: true,
    dataSort: false
  },


];

const endPoint = '/marca/novo/';
const endPointView = '/marca/edit/';
class UsuarioCrudList extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
  }

  handleRowClick = (row: e) => {
    this.props.history.push(endPointView + row.id);
  };

  handleNewButton = (row: e) => {
    this.props.history.push(endPoint);
    this.props.history.push({
      pathname: endPoint,
      state: {
        titlePage: 'Novo Usuario'
      }
    });
  };
  render() {
    let fields = _.map(columns, function (coluna) {
      return coluna.path;
    });
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    return (
      <React.Fragment>
        <TopoTitleComponente mainTitle="Clientes" subTitle=" " canBack={false} />
        <ListagemPage
          title={`Usuarios`}
          newButtonActionClick={()=>{
            this.props.history.push('/usuario/novo')
          }}
          delete={() => { }}
          service={userService}
          showNewButton={true}
          labelNewButton="Novo Usuario"
          defaultOrder={'nome'}
          defaultDirection={'asc'}
          defaultFilter={{}}
          onRowClick={(user)=>
    {
      console.log(user);
    console.log(user._links.self.href)
      this.props.history.push("/usuario/"+defaultService.getIdFromUrl(user._links.self.href))

      //this.handleRowClick
    }}
          FormFilter={UsuarioCrudListFilter}
          controller="usuario"
          fields={fields}
    array={'usuarios'}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(UsuarioCrudList);
