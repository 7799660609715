import * as React from "react";
import {Formik} from 'formik';
import _ from 'lodash';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import FormSelectInput from '../../components/Inputs/FormSelectInput';
import SelectInput from '../../components/Inputs/SelectInput';
import Toast from '../../components/Toasts/Toast';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import {userService} from "../../services/user.service";
import FormInput from "../../components/Inputs/FormInput";
import CpfCnpjInput from "../../components/Inputs/CpfCnpjInput";
import {defaultService} from "../../services/defaultService";
import {objectsConstants} from "../../constants/objects.constants";
import {produtoService} from "../../services/produto.service"
import {clienteService} from "../../services/cliente.service";
import {pedidosService} from "../../services/pedidos.service";
import "./Dashboard.css";
import UsuarioCrudListFilter from "../usuarios/UsuarioCrudListFilter";
import ListagemPage from "../../components/templates/ListagemPage";
import PedidosCrudListFilter from "../pedidos/PedidosCrudListFilter";
import {TableHeaderColumn} from "react-bootstrap-table";
import {tableHelper} from "../../helpers/tableHelper";
import {dateHelper} from "../../helpers/date.helper";
import Datatable from "../../components/table/Datatable";
import {InputFilter} from "../../components/Inputs/InputFilter"
import ChangeStatusModal from "../../components/Modals/ChangeStatusModal";
import moment from 'moment';
const endPointView = '/pedidos/edit/';
const columns = [
    {
        path: 'status',
        title: 'Situação',
        isStatus: true
    },
    {
        path: 'numeroPedido',
        title: 'Número do pedido',
        isKey: true,
        dataSort: false,
        className: 'txtBold'
    },
    {
        path: 'cliente.nomeFantasia',
        title: 'Cliente',
        isKey: false,
        dataSort: false,

    },
    {
        path: 'statusAlcis',
        title: 'Status WMS',
        isKey: false,
        dataSort: false,

    },
    {
        path: 'dataHoraColeta',
        title: 'Data e Hora da Expedição',
        isKey: false,
        isDateTime: true,
        dataSort: false,

    }, {
        path: 'concluido',
        title: 'Concluido',
        isKey: false,
        yesNo: true,
        dataSort: false,

    },


];

class Dashboard extends React.PureComponent<Props> {

    constructor(props) {
        super(props)
        this.state = {pedidos: [], showModal: false}

    }

    atualizaDash = () => {
        pedidosService.findAll().then(r => {
            this.setState({pedidos: r.data})
        })
        if (this.refTable) {
            this.refTable.getWrappedInstance().updateTable();
        }
    }

    componentDidMount() {
        this.interval = setInterval(this.atualizaDash, 10000);
        pedidosService.findAll().then(r => {
            this.setState({pedidos: r.data})
        })

    }

    getStatusColumn = (row, column) => {
        if (row.status == 'CRIADO') {
            return <div><span className={'bullet criado'}></span></div>;
        }
        if (row.status == 'SEPARACAO_ATRASADA') {
            return <div><span className={'bullet atrasado'}></span></div>;
        }
        if (row.status == 'FATURAMENTO_ATRASADO') {
            return <div><span className={'bullet atrasado-faturamento'}></span></div>;
        }
        if (row.status == 'EM_SEPARACAO') {
            return <div><span className={'bullet em-separacao'}></span></div>;
        }
        if (row.status == 'A_FATURAR') {
            return <div><span className={'bullet a-faturar'}></span></div>;
        }
        if (row.status == 'FATURADO') {
            return <div><span className={'bullet faturado'}></span></div>;
        }
    }
    customRender = (row, extra, column) => {
        let value = _.get(extra, column.path);
        if (column.isStatus) {
            return this.getStatusColumn(extra, column);
        }


        return tableHelper.renderCustom(row, extra, column)
    }

    toogle = (pedido) => {
        this.setState({showModal: !this.state.showModal, pedido})
    }
    getListPedidoStatus = (status) => {
        return _.filter(this.state.pedidos, {status});
    }
    getUltimoPedidoPorStatus = (pedidosPorStatus) => {
        return _.maxBy(pedidosPorStatus, 'ultimaAtualizacao'); // Supondo que 'dataAlteracao' é o nome do campo que contém a data de alteração.
    }
    formatDate = (timestamp) => {
        return moment(timestamp).format('DD/MM/YYYY HH:mm:ss');
    }
 openPedido = (id)=>{
     this.props.history.push(endPointView + id);
 }
    render() {
        const {pedidos} = this.state;
        let fields = _.map(columns, function (coluna) {
            return coluna.path;
        });
        const pedidosCriados = _.filter(pedidos, (p) => {
            return p.status == 'CRIADO'
        });
        const pedidosSeparacaoAtrasada = _.filter(pedidos, (p) => {
            return p.status == 'SEPARACAO_ATRASADA'
        });
        const pedidosFaturamentoAtrasado = _.filter(pedidos, (p) => {
            return p.status == 'FATURAMENTO_ATRASADO'
        });
        const pedidosEmSeparacao = _.filter(pedidos, (p) => {
            return p.status == 'EM_SEPARACAO'
        });
        const pedidosAguardandoFaturamento = _.filter(pedidos, (p) => {
            return p.status == 'A_FATURAR'
        });
        const pedidosFaturados = _.filter(pedidos, (p) => {
            return p.status == 'FATURADO'
        });
        const tableColumn = _.map(columns, (column, index) => (
            <TableHeaderColumn
                key={index}
                dataField={column.path}
                dataFormat={this.customRender}
                formatExtraData={column}
                isKey={column.isKey}
                dataSort={column.dataSort}
                hidden={column.hidden}
            >
                {column.title}
            </TableHeaderColumn>
        ))
        return (
            <React.Fragment>
                <div className={'row p-3'}>
                    <ChangeStatusModal isModalVisible={this.state.showModal} toogleModal={this.toogle}
                                       pedido={this.state.pedido}/>
                    <div className="col-2 p-3 align-self-stretch">
                        <div className="card bg-c-lightblue order-card card-concluido">
                            <div className="card-block">
                                <h2 className="m-b-20">PEDIDOS PRONTOS PARA EXPEDIÇÃO</h2>
                                <hr/>
                                <h3><span>{pedidos.filter(p => p.concluido&&p.statusAlcis!=='expedido' ).length}</span> PEDIDO(S)</h3>
                                {pedidos.filter(p => p.concluido&&p.statusAlcis!=='expedido').map(pedido => (
                                    <div className={'item-concluido'} onClick={()=>{this.openPedido(pedido.id)}}>
                                        <p className="m-b-0">Pedido: {pedido.numeroPedido}</p>
                                        <p className="m-b-0">Cliente: {pedido.cliente.nomeFantasia}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {/* Card de Tamanho 9 */}
                    <div className="col-10 p-3">
                        <div class="row w-100 col-12">
                            <div className="col">
                                <div className="card bg-c-gray order-card">
                                    <div className="card-block">
                                        <h6 className="m-b-20">{pedidosCriados.length}</h6>

                                        <p className="m-b-0">CRIADOS</p>
                                        {(() => {
                                            const ultimoPedido = this.getUltimoPedidoPorStatus(pedidosCriados);
                                            if (ultimoPedido) {
                                                return (
                                                    <React.Fragment>
                                                        <p style={{fontSize: '0.7rem'}} className="m-t-10">Último
                                                            Pedido: {ultimoPedido.numeroPedido}</p>
                                                        <p style={{fontSize: '0.7rem'}}>Data: {this.formatDate(ultimoPedido.ultimaAtualizacao)}</p>
                                                    </React.Fragment>
                                                );
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="card bg-c-pink order-card">
                                    <div class="card-block">
                                        <h6 class="m-b-20">{pedidosSeparacaoAtrasada.length}</h6>

                                        <p class="m-b-0">SEPARAÇÕES ATRASADAS</p>
                                        {(() => {
                                            const ultimoPedido = this.getUltimoPedidoPorStatus(pedidosSeparacaoAtrasada);
                                            if (ultimoPedido) {
                                                return (
                                                    <React.Fragment>
                                                        <p style={{fontSize: '0.7rem'}} className="m-t-10">Último
                                                            Pedido: {ultimoPedido.numeroPedido}</p>
                                                        <p style={{fontSize: '0.7rem'}}>Data: {this.formatDate(ultimoPedido.ultimaAtualizacao)}</p>
                                                    </React.Fragment>
                                                );
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card bg-c-pink-faturamento order-card">
                                    <div className="card-block">
                                        <h6 className="m-b-20">{pedidosFaturamentoAtrasado.length}</h6>

                                        <p className="m-b-0">FATURAMENTO ATRASADO</p>
                                        {(() => {
                                            const ultimoPedido = this.getUltimoPedidoPorStatus(pedidosFaturamentoAtrasado);
                                            if (ultimoPedido) {
                                                return (
                                                    <React.Fragment>
                                                        <p style={{fontSize: '0.7rem'}} className="m-t-10">Último
                                                            Pedido: {ultimoPedido.numeroPedido}</p>
                                                        <p style={{fontSize: '0.7rem'}}>Data: {this.formatDate(ultimoPedido.ultimaAtualizacao)}</p>
                                                    </React.Fragment>
                                                );
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="card bg-c-yellow order-card">
                                    <div className="card-block">
                                        <h6 className="m-b-20">{pedidosEmSeparacao.length}</h6>
                                        <p className="m-b-0">EM SEPARAÇÃO</p>
                                        {(() => {
                                            const ultimoPedido = this.getUltimoPedidoPorStatus(pedidosEmSeparacao);
                                            if (ultimoPedido) {
                                                return (
                                                    <React.Fragment>
                                                        <p style={{fontSize: '0.7rem'}} className="m-t-10">Último
                                                            Pedido: {ultimoPedido.numeroPedido}</p>
                                                        <p style={{fontSize: '0.7rem'}}>Data: {this.formatDate(ultimoPedido.ultimaAtualizacao)}</p>
                                                    </React.Fragment>
                                                );
                                            }
                                        })()}
                                    </div>

                                </div>
                            </div>

                            <div className="col">
                                <div className="card bg-c-blue order-card">
                                    <div className="card-block">
                                        <h6 className="m-b-20">{pedidosAguardandoFaturamento.length}</h6>

                                        <p className="m-b-0">CONFERIDO AGUARDANDO FATURAMENTO</p>
                                        {(() => {
                                            const ultimoPedido = this.getUltimoPedidoPorStatus(pedidosAguardandoFaturamento);
                                            if (ultimoPedido) {
                                                return (
                                                    <React.Fragment>
                                                        <p style={{fontSize: '0.7rem'}} className="m-t-10">Último
                                                            Pedido: {ultimoPedido.numeroPedido}</p>
                                                        <p style={{fontSize: '0.7rem'}}>Data: {this.formatDate(ultimoPedido.ultimaAtualizacao)}</p>
                                                    </React.Fragment>
                                                );
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>

                            <div className="col">
                                <div className="card bg-c-green order-card">
                                    <div className="card-block">
                                        <h6 className="m-b-20">{pedidosFaturados.length}</h6>

                                        <p className="m-b-0">FATURADO AGUARDANDO EXPEDIÇÃO</p>
                                        {(() => {
                                            const ultimoPedido = this.getUltimoPedidoPorStatus(pedidosFaturados);
                                            if (ultimoPedido) {
                                                return (
                                                    <React.Fragment>
                                                        <p style={{fontSize: '0.7rem'}} className="m-t-10">Último
                                                            Pedido: {ultimoPedido.numeroPedido}</p>
                                                        <p style={{fontSize: '0.7rem'}}>Data: {this.formatDate(ultimoPedido.ultimaAtualizacao)}</p>
                                                    </React.Fragment>
                                                );
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'row w-100 px-5'}>
                            <div className={'col'}>
                                <label>Situação</label>
                                <InputFilter
                                    type="text"
                                    className={`form-control w-100`}
                                    name={`situacao`}
                                    placeholder=""
                                    value={""}
                                    service={(value) => {
                                        this.setState({situacao: value}, () => {
                                            this.refTable.getWrappedInstance().updateTable();
                                        })
                                    }}
                                />
                            </div>
                            <div className={'col'}>
                                <label>Número Pedido</label>
                                <InputFilter
                                    type="number"
                                    className={`form-control w-100`}
                                    name={`situacao`}
                                    placeholder=""
                                    value={""}
                                    service={(value) => {
                                        this.setState({numeroPedido: value}, () => {
                                            this.refTable.getWrappedInstance().updateTable();
                                        })
                                    }}
                                />
                            </div>
                            <div className={'col'}>
                                <label>Cliente</label>
                                <InputFilter
                                    type="text"
                                    className={`form-control w-100`}
                                    name={`situacao`}
                                    placeholder=""
                                    value={""}
                                    service={(value) => {
                                        this.setState({cliente: value}, () => {
                                            this.refTable.getWrappedInstance().updateTable();
                                        })
                                    }}
                                />
                            </div>
                            <div className={'col'}>
                                <label>Status</label>
                                <InputFilter
                                    type="text"
                                    className={`form-control w-100`}
                                    name={`situacao`}
                                    placeholder=""
                                    value={""}
                                    service={(value) => {
                                        this.setState({status: value}, () => {
                                            this.refTable.getWrappedInstance().updateTable();
                                        })
                                    }}
                                />
                            </div>
                            <div className={'col'}>
                                <label>Data Hora da Coleta</label>
                                <InputFilter
                                    type="text"
                                    className={`form-control w-100`}
                                    name={`dataHoraColeta`}
                                    placeholder=""
                                    value={""}
                                    service={(value) => {
                                        this.setState({dataHoraColeta: value}, () => {
                                            this.refTable.getWrappedInstance().updateTable();
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className={'px-5'}>
                            <Datatable
                                service={pedidosService}
                                filter={{
                                    numeroPedido: this.state.numeroPedido,
                                    situacao: this.state.situacao,
                                    cliente: this.state.cliente,
                                    status: this.state.status,
                                    dashboard:true
                                }}
                                onRowClick={(pedido) => {
                                    this.toogle(pedido)
                                }}
                                defaultOrder={'nome'}
                                defaultDirection={'asc'}
                                defaultFilter={{numeroPedido: this.state.numeroPedido,dashboard:true}}

                                fields={fields}
                                array={'pedidos'}
                                ref={form => {
                                    this.refTable = form;
                                }}
                            >
                                {tableColumn}
                            </Datatable>

                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    const {user} = state.authentication;

    return {
        user,

    };
}

const mapDispatch = ({
                         alert: {success, error, clear},
                         load: {loading},
                     }) => ({
    success: (msg) => success(msg),
    loading: (load: boolean) => loading({load}),
    error: (msg) => error(msg),
    clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
    forwardRef: true,
})(Dashboard)