//@flow
import React from 'react';
import { toastr } from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import IconErrorToast from '../components/ToastIcons/IconErrorToast';
import { alertConstants } from '../constants/alert.constants';

export const alertActions = {
  success,
  error,
  clear,
  successInfo,
  serverError,
  someMessage
};

function success(message: string, code?: string, options?: any) {
  toastr.success((code ? code : ''), ' ' + message + '', options);
  return { type: alertConstants.SUCCESS, message, code };
}

function error(message: string, code?: string, options?: any) {
  toastr.error((code ? code : ''), '   ' + message + '', {
    icon: <IconErrorToast />
  });
  return { type: alertConstants.ERROR, message, code };
}

function clear() {
  toastr.clean();
  return { type: alertConstants.CLEAR };
}

function successInfo(message: string, code: string) {
  toastr.info('', (code ? code : '') + '  ' + message + '');
  return { type: alertConstants.SUCCESS_INFO, message, code };
}

function serverError() {
  return {
    type: alertConstants.ERROR,
    message: 'Serviço temporariamente indisponível.'
  };
}

function someMessage(message: string) {
  toastr.info('', message);
}
