//@flow
import ptBR from "date-fns/locale/pt-BR";
import * as moment from 'moment';
import React, {PureComponent} from 'react';
import DatePicker, {CalendarContainer, registerLocale} from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import ReactInputMask from 'react-input-mask';
import {connect} from 'react-redux';
import {Label} from 'reactstrap';
import {translate} from '../../helpers/message.helper';
import {ToolTipFeedBack} from '../Utils/ToolTipFeedBack';


type Props = {
  label: string,
  id: string,
  name: string,
  size?: number,
  onChange: any,
  required: boolean,
  messageValidate?: string,
  value?: any,
  placeholder: string,
  style: any,
  disabled: boolean
};


class DatePickerInput extends PureComponent<Props> {
  _datePicker;
  static defaultProps = {
    required: false,
    size: 12
  };

  constructor(props) {
    super(props)

  }

  componentDidMount() {
    registerLocale("pt-BR", ptBR);
  }

  handleBorderColor = messageValidate => {
    let borderColor;
    if (messageValidate) {
      borderColor = '#dc3545';
    }
    return {
      borderColor
    };
  };

  handleChange = (date: any) => {
    if (this.props.onChange) {
      this.props.onChange(this.props.name, date);

    }
  };



  render() {
    const { messages, name } = this.props;

    let colSize = 'col';
    if (this.props.size) {
      colSize += '-' + this.props.size;
    }

    let messageValidate;
    if (messages != null && messages.length > 0) {
      messages.forEach(function (message, index) {
        if (message.fieldName == name) {
          messageValidate = translate(message.message.code);
        }
      });
    }

    let selectedValue = this.props.value ? moment(this.props.value).toDate() : '';

    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }





    return (
        <div className={`form-group ${this.props.required ? 'input-required' : ''} ${this.props.style} ${colSize}`}>

          {this.props.label && (
              <Label for={this.props.name} className='label-nowrap' title={this.props.label}>
                {this.props.label}
                {this.props.required && '*'}
              </Label>
          )}

          <DatePicker
              disabled={this.props.disabled}
              id={this.props.id}
              name={this.props.name}
              className={`form-control ${messageValidate ? 'is-invalid' : ''}`}
              selected={selectedValue}
              onChange={this.handleChange}
              required={this.props.required}
              locale={'pt-BR'}
              dateFormat="dd/MM/yyyy"

              style={{ borderColor: 'red' }}
              placeholderText={this.props.placeholder}
              onBlur={this.props.onBlur}
              customInput={<ReactInputMask mask="99/99/9999" value={selectedValue} />}
              popperPlacement={this.props.popperPlacement}
              maxDate={this.props.maxDate ? moment(this.props.maxDate) : null}
              minDate={this.props.minDate ? moment(this.props.minDate) : null}
              //customInput={(<CustomInput calendar={this._calendar}/>)}
              popperContainer={CalendarContainer}

          />
          {messageValidate && (
              <ToolTipFeedBack
                  target={this.props.id ? this.props.id : this.props.name}
                  messageValidate={messageValidate}
              />
          )}
        </div>
    );
  }
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(DatePickerInput);
