import * as React from 'react';
import connect from 'react-redux/es/connect/connect';
import { NavLink, withRouter } from 'react-router-dom';
import _ from 'lodash';
import UploadPictureRegister from '../../components/UploadPictureRegister';
import { constHelper } from '../../constants/objects.constants';



let Core = require('../../js/sidePluguin');

type Props = {};
const options = {
  // duration od animations
  duration: 300,
  timer: 500,
  timerCurto: 300,
  timerLongo: 2000,
  // set small sidebar when window width < resizeWnd
  resizeWnd: 1000
};

class Menu extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      formaPagamento: {}
    }
  };

  componentDidMount() {
    let core = new Core(this.el, options, document.body, null, window);
    core.init();

  }

  getDataUrl = entity => {
    if (entity.foto && entity.foto.id == undefined && entity.foto.data) {
      return entity.foto.data;
    }
  };

  verificaPagamento = () => {
    console.log('verifica pagamento');

  }

  changeImage = imageVO => {
    console.log(imageVO);
  };

  render() {
    const { permissions } = this.props;
    const { formaPagamento } = this.state;
    return (
      <div ref={node => this.node = node} className="sidebar sidebar-hide-to-small sidebar-shrink ">
        <div className="nano" ref={el => (this.el = el)}>
          <div className="nano-content">

            <div className="iconeCollapse sidebar-toggle">
              <img src={require('../../img/icon_closeBlack.svg')} alt="Fechar modal" />
            </div>
            <div className="logo">
              <div className="logoMarca"></div>
            </div>
            <div className="perfil ">
              <div className="imgPerfil my-2">
                <UploadPictureRegister
                  name="foto"
                  hideLabel={true}
                  onChange={this.changeImage}
                  idImage={
                    this.props.user.foto ? this.props.user.foto.id : null
                  }
                  dataUrl={this.getDataUrl(this.props.user)}
                  id="foto"
                />

              </div>
              <div className="dadosPerfil row justify-content-center mt-2">
                <div className="text-center">

                  <h3 className="mb-3">{this.props.user.nomeExibicao !== "null " ? this.props.user.nomeExibicao : this.props.user.email}</h3>
                  <p className="cargo mt-n1">
                    {_.get(constHelper, this.props.user.role)}
                  </p>
                  {!this.props.user.cliente&&(
                  <button
                    type="button"
                    id="btnConfig"
                    className="btn btn-primary btn-sm"
                    onClick={() => {
                      this.props.history.push(
                        '/configuracoes/view/'
                      );
                    }}
                  >
                    Configurações
                  </button>)}
                </div>
              </div>
            </div>
            <ul className="menu">
              {!this.props.user.cliente&&(
              <li>
                <NavLink className="link" to="/dashboard">
                  <i className="icon-relt-1" /> Painel de situação
                </NavLink>
              </li>
              )}
              {!this.props.user.cliente&&(
              <li>
                <NavLink className="link" to="/cliente">
                  <i className="icon-posto" /> Clientes
                  </NavLink>
              </li>
              )}
              {!this.props.user.cliente&&(
                  <li>
                    <NavLink className="link" to="/notas">
                      <i className="icon-posto" /> Importar Nota Fiscal
                    </NavLink>
                  </li>
              )}
              {!this.props.user.cliente&&(
                <li>
                  <NavLink className="link" to="/usuario">
                    <i className="icon-colab" /> Usuários
                  </NavLink>
                </li>
              )}

        <li>
        <NavLink className="link" to="/pedidos">
        <i className="icon-contract" /> Pedidos
        </NavLink>
        </li>







              <li className="link" onClick={this.props.logoff}>
                <a>
                  <i className="icon-logoff" /> Sair do sistema
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, permissions } = state.authentication;
  const { temPagamento } = state.postoAutorizado;

  return {
    user,
    permissions,
    temPagamento
  };
}

const mapDispatch = ({ authentication: { logoff }, postoAutorizado: { setTotalLicensas, setPagamento } }) => ({
  logoff: () => logoff(),
  setTotalLicensas: (total) => setTotalLicensas(total),
  setPagamento: (temPagamento: boolean) => setPagamento(temPagamento),
});

const connectedMenu = withRouter(
  connect(
    mapStateToProps,
    mapDispatch
  )(Menu)
);
export { connectedMenu as Menu };
