export const formatterHelper = {
  cpfCnpjFormatter,
  getNameOrHifen,
  money
};

function money(valor) {
  return formatMoney(valor, 2, ",", ".");
}

function formatMoney(n, c, d, t) {
  var c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d == undefined ? "." : d,
    t = t == undefined ? "," : t,
    s = n < 0 ? "-" : "",
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    "R$ " +
    s +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}

function getNameOrHifen(name: String, defaultValue: String) {
  if (!name) {
    return defaultValue ? defaultValue : "- -";
  }
  try {    
    return name;
  } catch (error) {
    return name;
  }
}

function cpfCnpjFormatter(values){
  if(values){
    let onlyNumber = values.replace(/\D/g,"");
    if(onlyNumber.length <=11){
      console.log(1);
      onlyNumber=onlyNumber.replace(/(\d{3})(\d)/,"$1.$2")
      onlyNumber=onlyNumber.replace(/(\d{3})(\d)/,"$1.$2")
      onlyNumber=onlyNumber.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
    }else{
      console.log(2);
      console.log(onlyNumber)
      //Coloca ponto entre o segundo e o terceiro dígitos
      onlyNumber=onlyNumber.replace(/^(\d{2})(\d)/,"$1.$2")
      console.log(onlyNumber)
      //Coloca ponto entre o quinto e o sexto dígitos
      onlyNumber=onlyNumber.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
      console.log(onlyNumber)
      //Coloca uma barra entre o oitavo e o nono dígitos
      onlyNumber=onlyNumber.replace(/\.(\d{3})(\d)/,".$1/$2")
      console.log(onlyNumber)
      //Coloca um hífen depois do bloco de quatro dígitos
      onlyNumber=onlyNumber.replace(/(\d{4})(\d)/,"$1-$2")
      console.log(onlyNumber)


    }
    return onlyNumber;
  }
}