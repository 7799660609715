//@flow
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

type Props = {
  handleClick: any,
  label?: string
};

class NewButton extends React.Component<Props> {
  handleClick = () => {
    if (!_.isUndefined(this.props.handleClick)) {
      if (_.isString(this.props.handleClick)) {
        this.props.history.push(`${this.props.handleClick}novo`);
      } else {
        this.props.handleClick();
      }
    }
  };

  render() {
    const { label } = this.props;
    return (
      <button
        onClick={this.handleClick}
        type="button"
        className="btn btn-primary btn-sm"
      >
        {label}
      </button>
    );
  }
}

const connectedNewButton = withRouter(NewButton);
export { connectedNewButton as NewButton };
