//@flow
import * as _ from "lodash";
import { urlsConstants } from '../constants/url.constant';
import {oauthHeader, oauthHeaderJson} from '../helpers/oauth-header';
import { defaultService } from './defaultService';


export const userService = {
  me,
  doGet,
  doSave,
  getCurrentUser,
  findByFiltro,
  getExtension,
  doDelete,
  esqueciSenha,
  updateRecoverPassword,
  isCurrent,


  updatePassword
};



function findByFiltro(
    options: Options,
    filter: any
): Promise<Array<>> {
  return defaultService.doGet(
      `${urlsConstants.USUARIO}?page=${options.page-1}`,
      options,
      filter
  );
}




function doDelete(id) {
  return defaultService.doDelete(`${urlsConstants.USUARIO}${id}`);
}

function doGet(id) {
  return defaultService.doGet(`${urlsConstants.USUARIO}${id}`);
}

function doSave(usuario: usuario) {
  return defaultService.doSave(urlsConstants.USUARIO, usuario);
}





function me() {
  const requestOptions = {
    method: 'GET',
    headers: oauthHeader()
  };
  console.log(requestOptions);
  console.log(oauthHeader());
  console.log(urlsConstants.USER_ME)
  return defaultService.doGet(urlsConstants.USER_ME).then(response => {

    if (response.data.error === 'invalid_token') {
      return Promise.reject('invalid_token');
    }
    localStorage.setItem('user', JSON.stringify(response.data));
    return response;
  });
}

/*
function updateFoto(image) {
  return defaultService.doPost(`${urlsConstants.USER}updateFoto`, image);
}
*/

function getCurrentUser() {
  let token = localStorage.getItem('user');
  if (!token) {
    return null;
  }
  let user = JSON.parse(token);
  return user;
}










function esqueciSenha(cpfSend) {
  return defaultService.doPost(
    `${urlsConstants.USER_PUBLIC}recoverPassword/`, cpfSend);
}



function updateRecoverPassword(values) {
  return defaultService.doPut(
    `${urlsConstants.USER_PUBLIC}updateRecoverPassword/`, values);
}
function updatePassword(values){
  return defaultService.doPut(
      `${urlsConstants.USUARIO}updatePassword/`, values);
}

function getExtension() {
  let user = localStorage.getItem("user");
  if (!user) {
    return false;
  }
  user = JSON.parse(user);
  return _.get(user, 'role.extension');
}


function isCurrent(user) {
  let currentUser = getCurrentUser();
  if (currentUser.id === user.id) {
    return true;
  }
  return false;
}

