
import React, { PureComponent } from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import Toast from '../../components/Toasts/Toast';
import { BodyWhite } from '../../components/templates/BodyWhite';
import {translate} from "../../helpers/message.helper";
// import LoginForm from '../../components/Inputs/Login/LoginForm';
import IconChave from '../../img/icon_access.svg';
import IconSingUp from '../../img/icon_SingUp.svg';
import PasswordField from '../../components/Inputs/Login/PasswordField';
import EmailField from '../../components/Inputs/Login/EmailField';
import { authenticationService } from '../../services/authentication.service';
import CheckBoxInput from '../../components/Inputs/CheckBoxInput';
import FormField from "../../components/Inputs/Login/FormField";
import logo from '../../img/logo.svg';

type Props = {
  dispatch: any,
  loggingIn: boolean,
  loggedIn: boolean,
  alert: any,
  fieldMessage: any
};

class IndexContent extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { emailValue: '', passwordValue: '', nameValue: '', viewCadastro: false, termo: false, checkAceite: true };

  }

  handleLogin = (e: SyntheticEvent<HTMLButtonElement>) => {
    if (e) {
      e.preventDefault();
    }
    const { emailValue, passwordValue, nomeValue } = this.state;
    const { codigoCupom } = this.props;
    this.props.clear();
    if (emailValue && passwordValue) {
      if (this.state.viewCadastro) {
        authenticationService.cadastroSimples({ name: nomeValue, email: emailValue, password: passwordValue, confirmPassword: passwordValue, codigoCupom: codigoCupom }).then(response => {
          console.log('response');
          console.log(response);
          this.props.login(emailValue, passwordValue);
        }).catch(() => {
          this.props.error('Não foi possível criar o cadastro.');
        });
      } else {
        this.props.login(emailValue, passwordValue);
      }
    } else {
      this.props.clear();
      this.props.error('Informe o E-mail e Senha');
    }
  };




  handleChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    this.setState({ [name]: value });
  };

  componentWillMount() {

  }

  componentDidMount() {

  }

  handleConfirmEmail = (id: number) => {
    this.props.clear();

    if (id) {
      this.props.loading(true);

      let entity = {
        key: id
      };

     /* return passwordRecoverService.confirm(entity).then(
        response => {
          let json = response.data;
          this.props.success({
            message:
              'E-mail confirmado com sucesso. Efetue login para concluir seu cadastro.'
          });
          this.props.loading(false);
          this.goToLocaltion('#sectionLogin');
        },
        error => {
          if (error.response.status === 404) {
            this.props.error('E-mail já foi confirmado, efetue login.');
          } else {
            this.props.error(
              'Não foi possível confirmar E-mail, tente mais tarde.'
            );
          }
          this.goToLocaltion('#sectionLogin');
          this.props.loading(false);
        }
      );*/
    }
  };


  fieldStateChanged = function (field) {
    return function (state) {
      this.setState({ [field]: state.errors.length === 0, [field + 'Value']: state.value })
    }.bind(this)
  }.bind(this)

  emailChanged = this.fieldStateChanged('email');
  passwordChanged = this.fieldStateChanged('password');
  nomeChanged = this.fieldStateChanged('name');

  render() {
    const { loggedIn,user,...otherProps } = this.props;
    const { email, password } = this.state;
    const formValidated = email && password;
    //this.props.logoff();
    if (loggedIn&&!user.cliente) {
      return <Redirect to={{ pathname: "/dashboard" }} />;
    }else if(loggedIn){
      return <Redirect to={{ pathname: "/pedidos" }} />;
    }

    console.log('...otherProps',this.props)
    return (
      <React.Fragment>
        <BodyWhite />
        <Toast />


        <section id="sectionLogin"
          className={`${this.state.viewCadastro ? 'back-index' : 'back-entrar'}`}>
          <Toast />
          <div className="container-fluid d-flex align-items-center logo-container">
          <div className="col-12 offset-sm-4 col-sm-4 d-flex flex-column align-items-center pt-4" >

          </div>
          </div>
          <div className="container-fluid min-vh-100 d-flex flex-column flex-sm-row align-items-center">

            <div className="col-12 col-sm-3 text-center p-5">
              <div className="logo">
                <div className="logoMarca"></div>
              </div>
            </div>

            <div className="border-sm-left col-12 offset-sm-1 col-sm-4 d-flex flex-column align-items-center" >




              <form className="index_form" onSubmit={this.handleLogin}>
                {this.state.viewCadastro && <FormField fieldId="name" label="Nome" placeholder="Insira seu Nome" onStateChanged={this.nomeChanged} required={true} {...otherProps} />}
                <EmailField fieldId="email" label="E-mail" placeholder="Insira um e-mail válido" onStateChanged={this.emailChanged} required {...otherProps}/>
                <PasswordField showStrength={this.state.viewCadastro} fieldId="password" label="Senha" placeholder="Insira sua senha" onStateChanged={this.passwordChanged} thresholdLength={7} minStrength={2} required {...otherProps}/>
                {!this.state.viewCadastro && (
                  <React.Fragment>
                    <div className="mb-2 link">

                    </div>
                    <button type="submit" disabled={!formValidated} className="float-right btn btn-sm btn-primary mb-4">
                      Acessar
                </button>
                  </React.Fragment>
                )}
                {this.state.viewCadastro && (
                  <button type="button" onClick={this.validaConta} disabled={!formValidated} className="float-right btn btn-sm btn-primary mb-4">
                    Criar conta
                  </button>
                )}
              </form>

            </div>
          </div >
        </section >
      </React.Fragment >
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn,user } = state.authentication;
  const { alert } = state;
  const { messages } = state.fieldMessage;
  const { codigoCupom } = state.postoAutorizado;

  return {
    loggedIn,
    alert,
    messages,
    codigoCupom,user
  };
}

const mapDispatch = ({
  alert: { error, clear, success },
  authentication: { login,loggedIn ,logoff},
  load: { loading }
}) => ({
  error: (message, code) => error({ message, code }),
  clear: () => clear(),
  success: msg => success(msg),
  login: (user, password) => login({ user, password }),
  loading: (load: boolean) => loading({ load }),
  logoff:()=>logoff()
});
export default connect(
  mapStateToProps,
  mapDispatch
)(IndexContent);
