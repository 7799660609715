//@flow
import React from 'react';
import type { ClienteType } from '../types/cliente.type';
import * as moment from 'moment';
import type { OrdemServicoType } from '../types/ordemServico.type';

type State = {
  cliente: ClienteType,
  ordemServico: OrdemServicoType
};

type PayloadCliente = {
  cliente: ClienteType
};

type PayloadOrdemServico = {
  osType: string
};
const DEFAULT_VALUE = "--,--";
export default {
  state: { cliente: {}, ordemServico: {},totalOsMes:0 },
  reducers: {
    clearOS: (state: State, payload: PayloadCliente) => {
      return Object.assign({}, state, {
        ordemServico: undefined,
        valorTotal: DEFAULT_VALUE
      });
    },
    setCliente: (state: State, payload: PayloadCliente) => {
      return Object.assign({}, state, {
        cliente: payload.cliente
      });
    },
    setOrdemServico: (state: State, payload: PayloadCliente) => {
      return Object.assign({}, state, {
        ordemServico: payload.ordemServico
      });
    },
    setTotalOsMes: (state: State, payload: PayloadCliente) => {
      return Object.assign({}, state, {
        totalOsMes: payload.totalOsMes
      });
    },
    setValorTotal: (state: State, payload: PayloadCliente) => {
      return Object.assign({}, state, {
        valorTotal: payload.valorTotal
      });
    },
    createOrdemServico: (state: State, payload: PayloadOrdemServico) => {

      return Object.assign({}, state, {
        ordemServico: {
          statusOS: { name: 'EM_CRIACAO', description: 'Em criação' },
          creationDateTime: moment().format('YYYY-MM-DDTHH:mm:ss'),
          dataStatus: moment().format('YYYY-MM-DDTHH:mm:ss'),
          garantia: { label: 'Não', value: false },
          garantiaServico: { label: 'Não', value: false },
          diasGarantiaServico: '',
          // relogioEstoque: { label: 'Não', value: false },
          dataNotaFiscal: '',
          numeroNotaFiscal: '',
          valorTotal: DEFAULT_VALUE,
          osType: payload.osType
        }
      });
    }
  }
};
