//@flow
type State = {
  temPagamento: boolean,
};


export default {
  state: { extension: null, codigoCupom: null, totalLicensas: 10, temPagamento: false },
  reducers: {
    setExtension: (state, payload) => {
      return Object.assign({}, state, {
        extension: payload
      });
    },
    setCodigoCupom: (state, payload) => {
      return Object.assign({}, state, {
        codigoCupom: payload
      });
    },
    setTotalLicensas: (state, payload) => {
      return Object.assign({}, state, {
        totalLicensas: payload
      });
    },
    setPagamento: (state, payload) => {
      return Object.assign({}, state, {
        temPagamento: payload,
      });
    },
  }

};
