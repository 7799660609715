//@flow
import * as React from 'react';
import Datatable from '../table/Datatable';
import { connect } from 'react-redux';
import _ from 'lodash';
import { objectsConstants } from './../../constants/objects.constants';
import axios from 'axios';
import CardDefaulFull from '../panels/CardDefaulFull';
import HeaderList from './HeaderList';
import Toast from '../Toasts/Toast';
import { Formik } from 'formik';
import { Collapse } from 'reactstrap';
import ExportExcelButton from '../Buttons/ExportExcelButton';
import { defaultService } from '../../services/defaultService';
let download = require('downloadjs');

type Props = {
  title?: string,
  children: any,
  FormFilter?: any,
  newButtonActionClick: Function | string,
  showNewButton: boolean,
  defaultOrder: string,
  classSignal: string,
  defaultDirection: string,
  service: any,
  deleteSuccess: string,
  labelNewButton: string,
  defaultFilter: any,
  onRowClick: any,
  noFilter?: boolean
};

type State = { filter: any, filterForm: any, submitFilter: boolean };

class ListagemPage extends React.Component<Props, State> {
  static defaultProps = {
    defaultOrder: 'id',
    showNewButton: true,
    deleteSuccess: 'Registro excluído com sucesso.',
    forceUpdate: false,
    loadOnInit: true
  };

  _datatable: any;
  _source: any;

  constructor(props: any) {
    super(props);
    console.log(this.props.filter);
    this._source = axios.CancelToken.source();
    let filter =
      this.props.filter && this.props.controller === this.props.page
        ? this.props.filter
        : this.props.defaultFilter
          ? this.props.defaultFilter
          : objectsConstants.defaultFilter;
    this.state = { filter, filterForm: {}, submitFilter: false, openSearch: _.isEmpty(this.props.filter) ?false:true };
  }

  handleValue = (value: any, name: string) => {
    const filter = _.cloneDeep(this.state.filter);
    _.set(filter, name, value);
    this.setState({ filter, submitFilter: false });
  };

  _handleChangeData = (name, value) => {
    const filter = _.cloneDeep(this.state.filter);
    _.set(filter, name, value);
    this.setState({ filter, submitFilter: false });
  };

  _handleChangeText = (name, value, type, checked) => {
    const newFilter = _.cloneDeep(this.state.filter);
    _.set(newFilter, name, type === 'checkbox' ? checked : value);
    this.setState({ filter: newFilter, submitFilter: false });
  };
  handleSelectChange = (name: string, option: any) => {
    const { filter } = this.state;
    let newfilter = _.cloneDeep(filter);
    _.set(newfilter, name, option);
    this.setState({ filter: newfilter, submitFilter: false });
  };
  handleFilter = (e: SyntheticEvent<>) => {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      filterForm: Object.assign({}, this.state.filter),
      submitFilter: true
    });
  };

  toggle = () => {
    this.setState({
      openSearch: !this.state.openSearch
    },()=>{
      if(!this.state.openSearch){
      this.setState({ filter:this.props.defaultFilter },()=>{
        this.handleFilter();
      });
      }
    });
  };
  handleClick = () => {
    this.props.loading(true);
    console.log();
    defaultService
      .exportToExcel(
        this.props.controller,
        this.state.filter,
        this.props.fields,
        this.props.labels
      )
      .then(
        response => {
          download(
            response.data,
            (this.props.downloadExcelName
              ? this.props.downloadExcelName
              : this.props.controller) + '.csv',
            'text/html'
          );
          this.props.loading(false);
        },
        () => {
          this.props.loading(false);
          this.props.error('Não foi possível exportar arquivo.');
        }
      );
  };

  componentWillUnmount() {
    this._source.cancel('Operation canceled due component being unmounted.');
  }

  componentWillReceiveProps(nextProps: any) {
    if (
      nextProps.defaultFilter &&
      !_.isEqual(nextProps.defaultFilter, this.props.defaultFilter)
    ) {
      this.setState({ filter: nextProps.defaultFilter }, () => {
        !this.props.notUpdateOnChangeFilter &&
          this._datatable.getWrappedInstance().updateTable();
      });
    } else if (nextProps.forceUpdate) {
      this._datatable.getWrappedInstance().updateTable();
    }
    this.setState({ submitFilter: false });
  }

  updateTable = () => {
    this._datatable.getWrappedInstance().updateTable();
  };

  render() {
    const {
      newButtonActionClick,
      showNewButton,
      labelNewButton,
      title,
      FormFilter,
      roleNewButton,
      FormCardListagem,
      hideExport,
      validationModal
    } = this.props;
    return (
      <CardDefaulFull>
        <HeaderList
          title={title}
          labelNewButton={labelNewButton}
          actionClick={newButtonActionClick}
          showNewButton={showNewButton}
          filter={this.state.filter}
          controller={this.props.controller}
          fields={this.props.fields}
          labels={this.props.labels}
          toogleSearch={this.toggle}
          isOpen={this.state.openSearch}
          roleNewButton={roleNewButton}
          hideExport={hideExport}
        />
        <Toast />

        <div className="conteudoCard">

          {FormFilter && (

            <Collapse isOpen={this.state.openSearch}>
              <div className="form-row searchBox">
                <div className="col-12 d-flex justify-content-between">
                  <div className="miniTxt">Pesquisa</div>
                  <button className="btn btn-light btn-sm " onClick={this.toggle}><span className="icon-closesearch">
          <span className="path1" />
          <span className="path2" />
        </span>{' '}Fechar pesquisa</button>
                </div>

                <Formik
                  validationSchema={validationModal}
                  validateOnBlur={false}
                  validateOnChange={false}
                  enableReinitialize={true}
                  initialValues={this.state.filter}
                  onSubmit={(values, actions) => {
                    this.handleFilter();
                  }}
                  ref={form => {
                    this.formRef = form;
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    validateForm,
                    setValues
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      noValidate
                      className="form-row col-12"
                    >
                      <FormFilter
                        onChangeSelect={this.handleSelectChange}
                        onChangeText={this._handleChangeText}
                        onChangeValue={this.handleValue}
                        onChangeDate={this._handleChangeData}
                        entity={this.state.filter}
                        errors={errors}
                      />
                      <div className="form-group col-12 col-sm-12 ">
                        <button
                          type="submit"
                          className="btn btn-secondary btn-sm float-left"
                        >
                          Buscar
                      </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </Collapse>

          )}

          {FormCardListagem && (
            <FormCardListagem
              filter={this.state.filter}
              updateTable={this.updateTable}
            />
          )}
          {/*<div className="d-flex mt-3 justify-content-center justify-content-sm-end">*/}
          {/*  <ExportExcelButton onClick={this.handleClick} />*/}
          {/*</div>*/}
          <Datatable
            ref={datatable => (this._datatable = datatable)}
            defaultOrder={this.props.defaultOrder}
            defaultDirection={this.props.defaultDirection}
            service={this.props.service}
            classSignal={this.props.classSignal}
            filter={this.props.defaultFilter}
            onRowClick={this.props.onRowClick}
            submitFilter={this.state.submitFilter}
            controller={this.props.controller}
            loadOnInit={this.props.loadOnInit}
    controller={this.props.controller}
          >
            {this.props.children}
          </Datatable>
        </div>
      </CardDefaulFull>
    );
  }
}

function mapStateToProps(state) {
  const { filter, page } = state.defaultFilter;

  return {
    filter,
    page
  };
}
const mapDispatch = ({ alert: { error, success }, load: { loading } }) => ({
  error: message => error({ message }),
  success: message => success({ message }),
  loading: (load: boolean) => loading({ load })
});

export default connect(
  mapStateToProps,
  mapDispatch,
  null,
  { withRef: true }
)(ListagemPage);
