//@flow
import _ from 'lodash';
import * as React from 'react';
import {TableHeaderColumn} from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import ListagemPage from '../../components/templates/ListagemPage';
import {tableHelper} from '../../helpers/tableHelper';
import ClienteCrudListFilter from './ClienteCrudListFilter';
import {objectsConstants} from '../../constants/objects.constants';
import {clienteService} from '../../services/cliente.service';
import TopoTitleComponente from "../Topo/TopoTitleComponente";

const columns = [

  {
    path: 'nomeFantasia',
    title: 'Nome Fantasia',
    isKey: false,
    dataSort: false,
    className: 'txtBold'
  },
  {
    path: 'razaoSocial',
    title: 'Razão social',
    isKey: false,
    dataSort: false
  },
  {
    path: 'cnpjCpf',
    title: 'Cnpj/Cpf',
    isKey: true,
    dataSort: false,
    className: 'txtBold',
    isCpfCnpj:true
  },,
  {
    path: 'statusWms',
    title: 'Status WMS',
    isKey: false,
    dataSort: false,

  },

];

const endPoint = '/marca/novo/';
const endPointView = '/marca/edit/';
class ClienteCrudList extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
  }

  handleRowClick = (row: e) => {
    this.props.history.push(endPointView + row.id);
  };

  handleNewButton = (row: e) => {
    this.props.history.push(endPoint);
    this.props.history.push({
      pathname: endPoint,
      state: {
        titlePage: 'Nova Marca'
      }
    });
  };
  render() {
    let fields = _.map(columns, function (coluna) {
      return coluna.path;
    });
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    return (
      <React.Fragment>
        <TopoTitleComponente mainTitle="Clientes" subTitle=" " canBack={false} />
        <ListagemPage
          title={`Clientes`}
          newButtonActionClick={this.handleNewButton}
          delete={() => { }}
          service={clienteService}
          showNewButton={false}
          labelNewButton="Nova Marca"
          defaultOrder={'nome'}
          defaultDirection={'asc'}
          defaultFilter={{}}
          onRowClick={()=>{//this.handleRowClick
             }}
          FormFilter={ClienteCrudListFilter}
          controller="cliente"
          fields={fields}
    array={'clientes'}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(ClienteCrudList);
