//@flow
import * as React from 'react';

import {Route, Switch} from 'react-router-dom';
import ClienteCrudList from "./ClienteCrudList";

export default class ClienteCrud extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/cliente"
            exact
            component={ClienteCrudList}
          />

        </Switch>
      </React.Fragment>
    );
  }
}
