import * as React from 'react';
import { defaultService } from '../../services/defaultService';
import { urlsConstants } from './../../constants/url.constant';
import { connect } from 'react-redux';
import { ToolTipFeedBack } from '../Utils/ToolTipFeedBack';
import { FormGroup, Label, Input, InputType } from 'reactstrap';
import { saveAs } from 'file-saver';
let download = require('downloadjs');

// let fileInput;
class FileInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dataFile: null
    };
    this.loadFile = this.loadFile.bind(this);
  }

  componentDidMount() {

    if (this.props.fileItem) {
      this.loadFile(this.props.fileItem);
    }
  }
  componentWillReceiveProps(nextProps: any) {


    if (nextProps.fileItem) {
      this.loadFile(nextProps.fileItem);
    }
  }
  loadFile = fileItem => {
    let _this = this;
    if (fileItem.data) {

        _this.setState({ dataFile: fileItem });
      };

  }

  onChange = (e: SyntheticEvent<>) => {
    var files = e.target.files;
    if (files.length) {
      var self = this;
      var maxWidth = this.props.maxWidth;
      var maxHeight = this.props.maxHeight;

      var midiaVO = {
        filename: files[0].name,
        length: files[0].size,
        contentType: files[0].type
      };
      resize(files[0], maxWidth, maxHeight, function(resizedDataUrl) {
        midiaVO.data = resizedDataUrl;
        if (self.props.saveData) {
          self.props.loading(true);
          self.props.saveData(self.props.entity.id, midiaVO).then(r => {
            self.props.onChange(self.props.name, { id: r.data.data });
            self.props.loading(false);
          });
        } else {
          self.props.onChange(self.props.name, midiaVO);
        }
        self.setState({ dataFile: midiaVO });
      });
    }
  };

  openInputFile = (event) => {
    event.preventDefault();
    if (this.props.fileItem) {
      var dataFile = this.state.dataFile;
      if (dataFile) {
        const blob = this.dataURLtoBlob(`${dataFile.data}`);
        saveAs(blob, dataFile.filename);
      }
      return false;
    }else {
      if(!this.props.readOnly) {
        this.inputFile.click();
      }
    }
  };

  dataURLtoBlob=(dataurl) =>{
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }
  render() {
    let file;
    let dataFile = this.state.dataFile;
    console.log(dataFile)
    file = (
      <a
        role="button"
        href="#"
        onClick={this.openInputFile}
        id={this.props.id}
        className={this.props.className}
      >
        {this.props.children}
        {dataFile && !this.props.children && (
          <img
            src={require('../../img/icon_archive.svg')}
            className="icon_archive"
            alt="Ícone de um arquivo"
          />
        )}
        {dataFile &&
          (this.props.forceLabel ? this.props.forceLabel : dataFile.filename)}
        {!dataFile && this.props.emptyLabel}
      </a>
    );

    return (
      <React.Fragment>
        <FormGroup
            className={` ${this.props.style} ${this.props.className
                }`}
        >
          {this.props.label && (
              <React.Fragment>
                <Label for={this.props.name}>
                  {this.props.label}
                  {this.props.required && '*'}
                </Label>{' '}

              </React.Fragment>
          )}
        <div className={this.props.className}>

          <input
            type="file"
            accept={this.props.accept ? this.props.accept : '*'}
            onChange={this.onChange}
            className="d-none img-profile-input"
            maxLength="1024"
            ref={input => {
              this.inputFile = input;
            }}
          />
          {file}
          {dataFile && !this.props.readOnly && <img
              src={require('../../img/icon_excluir.svg')}
              className="icon_exclude action"
              alt="Ícone de um arquivo"
              onClick={()=>{
                this.setState({ dataFile: null },()=>{
                  this.props.onChange(this.props.name, null);
                });
              }}
          />}
          {this.props.erroMensagem && (
            <ToolTipFeedBack
              target={this.props.id}
              messageValidate={this.props.erroMensagem}
            />
          )}

        </div>
        </FormGroup>
      </React.Fragment>
    );
  }
}

function resize(file, maxWidth, maxHeight, fn) {
  var reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function(event) {
    var dataUrl = event.target.result;
    fn(dataUrl);
  };
}

const mapDispatch = ({ load: { loading } }) => ({
  loading: (load: boolean) => loading({ load })
});
function mapStateToProps(state) {
  const { currentTask } = state.task;

  return {
    currentTask
  };
}
export default connect(
  mapStateToProps,
  mapDispatch,
  null,
  { withRef: true }
)(FileInput);
