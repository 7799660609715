//@flow
import _ from 'lodash';
import * as React from 'react';
import {TableHeaderColumn} from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import ListagemPage from '../../components/templates/ListagemPage';
import NotasCrudListFilter from './NotasCrudListFilter';
import {pedidosService} from '../../services/pedidos.service';
import TopoTitleComponente from "../Topo/TopoTitleComponente";
import {defaultService} from "../../services/defaultService";
import {tableHelper} from '../../helpers/tableHelper';
import {notasService} from "../../services/notas.service";
const columns = [
  {
    path: 'numeroPedido',
    title: 'Número do pedido',
    isKey: true,
    dataSort: false,
    className: 'txtBold'
  },
  {
    path: 'cliente.nomeFantasia',
    title: 'Cliente',
    isKey: false,
    dataSort: false,

  },



];

const endPoint = '/notas/novo/';
const endPointView = '/notas/edit/';
class NotasCrudList extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
  }

  handleRowClick = (row: e) => {
   let id =  defaultService.getIdFromUrl(row._links.self.href)
    this.props.history.push(endPointView + id);
  };

  handleNewButton = (row: e) => {
    this.props.history.push(endPoint);
    this.props.history.push({
      pathname: endPoint,
      state: {
        titlePage: 'Nova Nota Fiscal'
      }
    });
  };
  render() {
    let fields = _.map(columns, function (coluna) {
      return coluna.path;
    });
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));

    return (
      <React.Fragment>
        <TopoTitleComponente mainTitle="Notas" subTitle=" " canBack={false} />
        <ListagemPage
          title={`Notas`}
          newButtonActionClick={this.handleNewButton}
          delete={() => { }}
          service={notasService}
          showNewButton={true}
          labelNewButton="Nova Nota Fiscal"
          defaultOrder={'nome'}
          defaultDirection={'asc'}

    onRowClick={

      this.handleRowClick
    }
          FormFilter={ NotasCrudListFilter}
          controller="notas"
          fields={fields}
    array={'Notas'}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(NotasCrudList);
