//@flow
import {urlsConstants} from '../constants/url.constant';
import {defaultService} from './defaultService';


export const clienteService = {

  findByFiltro,
  findAll

};

function findAll(){
  return defaultService.doGet(`${urlsConstants.API_CLIENTE}findAll`)
}

function findByFiltro(
  options: Options,
  filter: any
): Promise<Array<>> {
  return defaultService.doGet(
    `${urlsConstants.CLIENTE}?page=${options.page-1}`,
    options,
    filter
  );
}