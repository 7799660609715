import * as React from "react";
import { Formik } from 'formik';
import _ from 'lodash';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import FormSelectInput from '../../components/Inputs/FormSelectInput';
import SelectInput from '../../components/Inputs/SelectInput';
import Toast from '../../components/Toasts/Toast';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import {userService} from "../../services/user.service";
import FormInput from "../../components/Inputs/FormInput";
import CpfCnpjInput from "../../components/Inputs/CpfCnpjInput";
import {defaultService} from "../../services/defaultService";
import {objectsConstants} from "../../constants/objects.constants";
import {produtoService} from "../../services/produto.service"
import {clienteService} from "../../services/cliente.service";
import {pedidosService} from "../../services/pedidos.service";
import moment from 'moment';
import CadastroPedidoModal from "../../components/Modals/CadastroPedidoModal";
import DatePickerInput from "../../components/Inputs/DatePickerInput";
import FileInput from "../../components/Inputs/FileInput";
import DateTimePicker from "../../components/Inputs/DateTimePicker";
import Switch from 'react-switch';
import AuditLogModal from "./AuditLogModal";
const PEDIDO_VALIDATE = Yup.object().shape({

    itens: Yup.array().of(
        Yup.object().shape({
            produto:
                Yup.object().shape({
                    id: Yup.string().required("Obrigatório")


                })
        }),


),
    dataHoraColeta: Yup.date().required("Obrigatório")
});


class PedidoEdit extends React.PureComponent<Props> {

constructor(props){
    super(props)
    this.state={entity:{},viewMode:false,index:null};
}

    componentDidMount() {
        console.log(this.props)
        if (this.props.match.params.id) {
            this.props.loading(true);
            pedidosService.doGet(this.props.match.params.id).then(
                (response) => {
                    console.log(response);
                    let pedido = response.data;
                    console.log(pedido);
                    Promise.all([pedidosService.validaEdicao(this.props.match.params.id),pedidosService.validaConclusao(this.props.match.params.id)]).then(responseViewMode=>{
                        console.log(responseViewMode);
                        this.setState({viewMode:responseViewMode[0].data,isConclued:responseViewMode[1].data},()=>{
                    if(pedido.itens&&pedido.itens.length>0) {
                        let produtos = _.uniqBy(pedido.itens, 'produto.codigoProduto');



                        Promise.all(_.map(produtos, (i) => {
                            return pedidosService.findEstoque(i.produto);
                        })).then(allResponse => {
                            let itens = [];
                            _.map(allResponse,(response,index)=>{




                                let produto = produtos[index].produto;

                                    produto.estoque = _.filter(pedido.itens,['produto.codigoProduto',produto.codigoProduto]).map((i)=>{
                                        return {lote:i.observacoes,quantidade:i.quantidade,dataDeValidade:i.dataValidade};
                                    })

                                itens.push( {produto});
                            })
                            pedido.itens = itens;
                            console.log(pedido);
                            this.props.loading(false);
                            this.setState({entity: pedido})
                        }
                            ,(errorEstoque)=>{
                                console.log(errorEstoque)
                                this.props.loading(false);
                            }

                        );
                    }else {
                        this.setState({entity: pedido}, () => {
                            this.props.loading(false);
                        });
                    }
                        })
                    },(errorViewMode)=>{
                        console.log(errorViewMode)
                        this.props.loading(true);
                    })
                },
                (error) => {
                    console.log('error');
                    console.log(error);
                    this.props.loading(false);
                }
          );
        }else{

                this.setState({entity:{cliente:this.props.user.cliente}});

        }
    }
     changeProduto = (value,setFieldValue,name,index) =>{
         if(value) {
             this.props.loading(true);
             pedidosService.findEstoque(value).then((r) => {
                 console.log(index)
                 setFieldValue(name, value);
                 setFieldValue('produtoEstoque',value);
                 setFieldValue('estoque',r.data);
                 setFieldValue('index',index);
                 this.props.loading(false);
             },(error)=>{
                 this.props.loading(false);
                 this.props.info({ message: 'Não existe saldo em estoque deste produto!'});
             })
         }else{
             setFieldValue(name,value)
             setFieldValue('produtoEstoque',null);
             setFieldValue('index',null);
         }
      }
    showError = messageError => {
        this.props.error({ message: messageError });
        return '';
    };

    showInfo = messageInfo => {
        this.props.info({ message: messageInfo });
        return '';
    };

    clearError = () => {
        this.props.clear();
        return '';
    };

    render() {
        const { entity } = this.state;
        console.log(entity);
       let _this = this;
        return (
            <React.Fragment>
            <TopoTitleComponente mainTitle="Pedidos" subTitle=" " canBack={true} />
        <Formik
        validationSchema={this.state.viewMode?{}:PEDIDO_VALIDATE}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={entity}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
            let insertable = values.id === null || values.id === undefined;
            let clientEntity = values;
            if(_.get(clientEntity,'_links.self.href')){
                _.set(clientEntity,'id',defaultService.getIdFromUrl(_.get(clientEntity,'_links.self.href')))
            }
            let produtos = []
           _.map(clientEntity.itens,(i)=>{

               _.map(i.produto.estoque,(estoque)=>{
                   if(estoque.quantidade&&estoque.quantidade>0){
                       console.log(estoque);
                       let produto = _.cloneDeep(i);
                       produto.quantidade = estoque.quantidade;
                       produto.observacoes = estoque.observacoes;
                       produto.dataValidade= estoque.dataDeValidade;
                       produto.produto.estoque=null;
                       produtos.push(produto)
                   }
               })
           })
            clientEntity.itens = produtos;
           pedidosService
                .doSave(clientEntity)
                .then(response => {
                    console.log(response)
                    let id = defaultService.getIdFromUrl(response.data._links.self.href);
                    values.id = id;

                    console.log('brasil');
                        this.props.history.push('/pedidos');


                    actions.setSubmitting(false);

                    this.props.success({ message: 'Pedido salvo com sucesso.' });
                })
                .catch((e) => {

                    console.log(e)
                    console.log('brasil22222');
                    actions.setSubmitting(false);
                });
        }}
    >
        {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue
          }) => (
            <form onSubmit={handleSubmit} noValidate>
        <div className="flex-column p-5">
            <Toast />
            <CadastroPedidoModal  estoque={values.estoque} index={_this.state.index}  isModalVisible={values.produtoEstoque} setFieldValue={setFieldValue}
                                  info={this.props.info}
                                  selectEstoque={(produtos)=>{
                                      console.log(produtos);
                                      console.log(_this.state);
                                      console.log(`itens[${values.index}].produto.estoque`);
                                      setFieldValue(
                                          `itens[${values.index}].produto.estoque`,produtos
                                      )
                                      setFieldValue(
                                          `estoque`,[]
                                      )
                                      setFieldValue(
                                          `produtoEstoque`,null
                                      );
                                      setFieldValue(
                                          `index`,null
                                      );
                                  }}
                                  onCancel={()=>{
                                      if(!_.get(values,
                                          `itens[${values.index}].produto.estoque`
                                      )){
                                          setFieldValue(
                                              `itens[${values.index}].produto`,null)

                                      }
                                      setFieldValue(
                                          `estoque`,[]
                                      )
                                      setFieldValue(
                                          `produtoEstoque`,null
                                      );
                                      setFieldValue(
                                          `index`,null
                                      );

                                  }}
            />
            {!_.isEmpty(errors) &&
        this.showError(objectsConstants.messageCampoObrigatorio)}

            {values.id }
        <div className="cardSeculus d-flex flex-column mb-0">
            <div className="topoCard d-flex flex-column flex-sm-row">
            <h3 className="flex-grow-1">Pedidos</h3>
            </div>

            <div className="form-row">
                {values.id&&(<AuditLogModal   pedidoId={values.id}/>
                )}
        {!this.props.user.cliente&&(
        <div className="form-group col-6 col-lg-6">
            {this.state.viewMode&&values.cliente&&(
                <p>{values.cliente.nomeFantasia}</p>
            )}
            {!this.state.viewMode&&values.cliente&&(
            <FormSelectInput
            name="cliente"
            id={'cliente'}
            label="Cliente"
            placeholder="Selecione o cliente"
            valueKey="id"
            labelKey="nomeFantasia"
            noSize={true}
            required={false}
            returnFullObject={true}
            isMulti={false}
            searchable={false}
            allowSelectAll={false}
            service={clienteService.findAll}
            onChange={setFieldValue}
            value={values.cliente}

            />
            )}


        </div>
        )}
                <div className="form-group col-3 col-lg-3">

                    {!this.state.viewMode&&values.cliente&&(
                        <FormInput
                            name="numeroPedidoCliente"
                            id={'numeroPedidoCliente'}
                            label="Número do pedido(Cliente)"
                            placeholder="Número pedido"

                            noSize={true}
                            required={false}
                            returnFullObject={true}
                            isMulti={false}
                            searchable={false}
                            allowSelectAll={false}

                            onChange={setFieldValue}
                            value={values.numeroPedidoCliente}

                        />
                    )}
                    {this.state.viewMode&&values.cliente&&(

                        <div>
                            <label className="label-nowrap">Número do pedido(Cliente):</label>
                            <div>
                                {values.numeroPedidoCliente}
                            </div>
                        </div>

                    )}

                </div>
                <div className="form-group col-3 col-lg-3">

                    {!this.state.viewMode&&values.cliente&&(
                        <FormInput
                            name="transportadora"
                            id={'transportadora'}
                            label="Transportadora"
                            placeholder="Transportadora"

                            noSize={true}
                            required={false}
                            returnFullObject={true}
                            isMulti={false}
                            searchable={false}
                            allowSelectAll={false}

                            onChange={setFieldValue}
                            value={values.transportadora}

                        />
                    )}
                    {this.state.viewMode&&values.cliente&&(

                        <div>
                            <label className="label-nowrap">Transportadora:</label>
                            <div>
                                {values.transportadora}
                            </div>
                        </div>

                    )}

                </div>
                <div className="form-group col-3 col-lg-3">

                    {!this.state.viewMode&&values.cliente&&(
                        <DateTimePicker
                            name="dataHoraColeta"
                            id={'dataHoraColeta'}
                            label="Data e Hora da Coleta"
                            placeholder="Data e Hora da Coleta"
                            erroMensagem={errors.dataHoraColeta}
                            noSize={true}
                            required={false}
                            returnFullObject={true}
                            isMulti={false}
                            searchable={false}
                            allowSelectAll={false}

                            onChange={setFieldValue}
                            value={values.dataHoraColeta}

                        />
                    )}

                    {this.state.viewMode&&values.cliente&&(

                        <div>
                            <label className="label-nowrap">Data e Hora da Coleta:</label>
                            <div>
                                {values.dataHoraColeta?moment(values.dataHoraColeta).format('DD/MM/yyyy hh:mm'):''}
                            </div>
                        </div>

                    )}
                </div>
                <div className="form-group col-1 col-lg-1 text-center">


                        <React.Fragment>
                            <div>
                            <label className="label-nowrap">Urgente:</label>
                                <div>
                                    {!this.state.viewMode&&values.cliente&&(
                        <Switch
                            onChange={(value) => setFieldValue('urgente',value)}
                            checked={values.urgente}
                            onCo lor={'#0080ff'}
                            offColor={'#d9d9d9'}
                            height={30}
                            width={60}
                            handleDiameter={28}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            activeBoxShadow={'none'}
                        />
                                    )}
                                    {this.state.viewMode&&values.cliente&&(values.urgente?'Sim':'Não')}
                                </div>
                            </div>
                        </React.Fragment>

                    {/* Campo switch para confirmação de conclusão */}

                </div>
                <div className="form-group col-2 col-lg-2 text-center">

                        <React.Fragment>
                            <div>
                                <label className="label-nowrap">Cadastro concluido:</label>
                                <div>
                                    {!this.state.isConclued&&values.cliente&&(
                                    <Switch
                                        onChange={(value) => setFieldValue('concluido',value)}
                                        checked={values.concluido}
                                        onColor={'#0080ff'}
                                        offColor={'#d9d9d9'}
                                        height={30}
                                        width={60}
                                        handleDiameter={28}
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                        activeBoxShadow={'none'}
                                    />
                                    )}
                                    {this.state.isConclued&&values.cliente&&(values.concluido?'Sim':'Não')}
                                </div>
                            </div>
                        </React.Fragment>

                </div>
                {/* Iniciando os campos de upload */}
                <div className="form-group col-4 col-lg-4">

                        <FileInput
                            name="nf"
                            id="NF"
                            label="NF"
                            readOnly={this.state.isConclued}
                            emptyLabel={"Adicionar NF"}
                            onChange={setFieldValue}
                            value={values.nf}
                            fileItem={values.nf}
                        />

                </div>
                <div className="form-group col-4 col-lg-4">

                        <FileInput
                            name="pv"
                            id="PV"
                            label="PV"
                            readOnly={this.state.isConclued}
                            emptyLabel={"Adicionar PV"}
                            onChange={setFieldValue}
                            value={values.pv}
                            fileItem={values.pv}
                        />

                </div>
                <div className="form-group col-4 col-lg-4">

                        <FileInput
                            name="tag"
                            id="TAG"
                            label="TAG"
                            readOnly={this.state.isConclued}
                            emptyLabel={"Adicionar TAG"}
                            onChange={setFieldValue}
                            value={values.tag}
                            fileItem={values.tag}
                        />

                </div>
                <div className="form-group col-4 col-lg-4">

                        <FileInput
                            name="cartaDeCorrecao"
                            id="CartaDeCorrecao"
                            label="Carta de Correção"
                            readOnly={this.state.isConclued}
                            emptyLabel={"Adicionar Carta de Correção"}
                            onChange={setFieldValue}
                            value={values.cartaDeCorrecao}
                            fileItem={values.cartaDeCorrecao}
                        />

                </div>
                <div className="form-group col-4 col-lg-4">

                        <FileInput
                            name="guiaDifal"
                            id="GuiaDifal"
                            label="Guia Difal"
                            readOnly={this.state.isConclued}
                            emptyLabel={"Adicionar Guia Difal"}
                            onChange={setFieldValue}
                            value={values.guiaDifal}
                            fileItem={values.guiaDifal}
                        />

                </div>
                <div className="form-group col-4 col-lg-4">

                        <FileInput
                            name="comprovanteDifal"
                            readOnly={this.state.isConclued}
                            id="ComprovanteDifal"
                            label="Comprovante Difal"
                            emptyLabel={"Adicionar Comprovante Difal"}
                            onChange={setFieldValue}
                            value={values.comprovanteDifal}
                            fileItem={values.comprovanteDifal}
                        />

                </div>


                <div className="form-group col-12 col-lg-12">

                    {!this.state.viewMode&&values.cliente&&(
                        <FormInput
                            name="observacoes"
                            id={'observacoes'}
                            label="Observações"
                            placeholder="Observações"

                            noSize={true}
                            required={false}
                            returnFullObject={true}
                            isMulti={false}
                            searchable={false}
                            allowSelectAll={false}

                            onChange={setFieldValue}
                            value={values.observacoes}

                        />
                    )}
                    {this.state.viewMode&&values.cliente&&(

                        <div>
                            <label className="label-nowrap">Observações:</label>
                            <div>
                                {values.observacoes}
                            </div>
                        </div>

                    )}

                </div>

        <div className="col-12">
            <p>Adicione abaixo os produtos para solicitar o pedido:</p>
            {!this.state.viewMode&&(
        <button type="button" className="btn btn-primary" onClick={()=>{
            let newItens = _.cloneDeep(values.itens);
            if(!newItens){
                newItens = [];
            }
            newItens.push({});
            setFieldValue('itens',newItens);
        }}>Adicionar Produto ao pedido</button>)}
        </div>

            {values.itens && values.itens.map((item,index)=>{
                return (

                    <div className="form-group col-12 col-lg-12 row">
                    <hr className={'col-12'}/>

                        {this.state.viewMode&&item.produto&&(
                            <div className=" form-group col-8">
                            <p>{item.produto.codigoProduto} - {item.produto.descricao}</p>
                            </div>
                        )}
                        {!this.state.viewMode&&values.cliente&&(
                       <React.Fragment>
                           <div className=" form-group col-4">
                           <FormSelectInput
                               name={`itens[${index}].produto`}
                               id={'produto'}
                               label="Código do Produto"
                               placeholder="Selecione o produto"
                               valueKey="id"
                               labelKey="codigoProduto"
                               parent={values.cliente}
                               disabled={!values.cliente}
                               noSize={true}
                               required={false}
                               returnFullObject={true}
                               isMulti={false}
                               searchable={false}
                               allowSelectAll={false}
                               service={produtoService.findAll}
                               onChange={(name,value)=>{
                                   this.changeProduto(value,setFieldValue,name,index)

                               }}
                               value={item.produto}
                               erroMensagem={_.get(errors,`itens[${index}].produto.id`)}
                           />
                               {console.log(errors)}
                           </div>
                           <div className=" form-group col-4">
                    <FormSelectInput
                name={`itens[${index}].produto`}
                id={'produto'}
                label="Descrição do Produto"
                placeholder="Selecione o produto"
                valueKey="id"
                labelKey="descricao"
                parent={values.cliente}
                disabled={!values.cliente}
                noSize={true}
                required={false}
                returnFullObject={true}
                isMulti={false}
                searchable={false}
                allowSelectAll={false}
                service={produtoService.findAll}
                onChange={(name,value)=>{
                  this.changeProduto(value,setFieldValue,name,index)
                }}
                value={item.produto}
                erroMensagem={_.get(errors,`itens[${index}].produto.id`)}
                />
                           </div>
                       </React.Fragment>
                        )}




                <div className="text-right col-1 align-self-center py-2">
                    {console.log(item)}
                    {!this.state.viewMode&&(
                    <a
                onClick={() =>{
               let newItens = _.cloneDeep(values.itens);
                newItens.splice(index,1);
                setFieldValue('itens',newItens);
            }}
            >
                remover
                </a>
                    )}
                </div>
                        {item.produto&& item.produto.estoque &&(
                            <table className={'col-12 table'}>
                                <tr>

                                    <th>Lote</th>
                                    <th>Data de Validade</th>
                                    <th>Quantidade</th>
                                </tr>
                                {item.produto.estoque.map((estoque,indexEstoque)=>{
                                   //  if(!this.state.viewMode){
                                   // return ( <tr>
                                   //     <td>{estoque.quantidadeDisponivel}</td>
                                   //     <td>{estoque.lote}</td>
                                   //     <td>{estoque.dataDeValidade&&moment(item.produto.estoque.dataDeValidade).format('DD/MM/YYYY')}</td>
                                   //     <td><div className="form-group col-12 col-lg-12 ">
                                   //
                                   //         <input
                                   //             type="number"
                                   //             className={`form-control ${errors.email &&
                                   //             touched.email &&
                                   //             'error'}`}
                                   //             name={`itens[${index}].produto.estoque[${indexEstoque}].quantidade`}
                                   //             placeholder="Digite a quantidade do produto"
                                   //             value={estoque.quantidade}
                                   //             onChange={handleChange}
                                   //         />
                                   //     </div></td>
                                   // </tr>)}else{
                                        if(estoque.quantidade){
                                            return ( <tr>

                                                <td>{_.get(estoque,'observacoes',_.get(estoque,'lote'))}</td>
                                                <td>{estoque.dataDeValidade&&moment(item.produto.estoque.dataDeValidade).format('DD/MM/YYYY')}</td>
                                                <td>{estoque.quantidade}</td>
                                            </tr>)
                                        }
                                    // }
                                })}

                            </table>

                        )}
                </div>

                )
            })}




        </div>
            {(!this.state.viewMode||!this.state.isConclued)&&(
        <div className="d-flex flex-row flex-sm-row ">
            <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
            <button
            type="button"
            className="btn btn-secondary btn30 white"
            disabled={isSubmitting}
            onClick={() => this.props.history.goBack()}
        >
            Cancelar
            </button>
            </div>
            <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
            <button
            type="submit"
            disabled={isSubmitting||!values.itens||values.itens.length==0}
            className="btn btn-secondary btn30 green"

                >
                Enviar pedido
                </button>
                </div>
                </div>
            )}
                </div>

                </div>
                </form>
        )}
    </Formik>
        </React.Fragment>
    );
    }
}


function mapStateToProps(state) {
    const { user } = state.authentication;

    return {
        user,

    };
}

const mapDispatch = ({
                         alert: { success, error, clear,info},
                         load: { loading },
                     }) => ({
    success: (msg) => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: (msg) => error(msg),
    info: (msg) => info(msg),
    clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
    forwardRef: true,
})(PedidoEdit)