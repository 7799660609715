import {urlsConstants} from '../constants/url.constant';
import {defaultService} from './defaultService';


export const configuracoesService = {

    findFirst,

    doSave


};




function findFirst(){
    return defaultService.doGet(`${urlsConstants.CONFIGURACOES}search/findFirstByOrderByIdDesc`)
}

function doSave(usuario: usuario) {
    return defaultService.doSave(urlsConstants.CONFIGURACOES, usuario);
}

