//@flow
import moment from "moment";
import ptBR from "date-fns/locale/pt-BR";
import React, { PureComponent } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Label } from "reactstrap";
import "./datePicker.css";
import { ToolTipFeedBack } from "../Utils/ToolTipFeedBack";
import { ToolTipHelp } from "../Utils/ToolTipHelp";
import { dateHelper } from "../../helpers/date.helper";
import 'moment/locale/pt-br';
import ReactInputMask from "react-input-mask";
import DatePicker, { CalendarContainer, registerLocale } from "react-datepicker";
import { date } from "yup";



type Props = {
  label: string,
  id: string,
  name: string,
  size?: number,
  onChange: any,
  required: boolean,
  messageValidate?: string,
  value?: any,
  placeholder: string,
  style: any,
  disabled: boolean
};

export default class DateTimePicker extends PureComponent<Props> {
  constructor(props) {
    super(props);
    this.state = { value: props.value };

  }
  componentDidMount() {
    registerLocale("pt-BR", ptBR);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value })
    }
  }

  handleBorderColor = messageValidate => {
    let borderColor;
    if (messageValidate) {
      borderColor = "#dc3545";
    }
    return {
      borderColor
    };
  };

  handleChange = (date: any) => {
    if (this.props.onChange) {
      this.setState({ value: date }, function () {
        if (this.props.format) {
          this.props.onChange(
            this.props.name,
            dateHelper.format(date, { pattern: this.props.format })
          );
        } else {
          this.props.onChange(this.props.name, date);
        }
      });
    } else {
      this.setState({ value: date });
    }
  };

  getFormat = () => {
    let format = "dd/MM/yyyy HH:mm";


    return format;
  };

  getValue = () => {
    let selectedValue;
 if(this.state.value) {
   selectedValue = this.state.value ? moment(this.state.value).toDate() : moment().toDate();
 }else{
   selectedValue = null
 }
    return selectedValue;
  };
  render() {
    const { messages, name } = this.props;
    let { messageValidate } = this.props;
    if (this.props.erroMensagem) {
      messageValidate = this.props.erroMensagem;
    }
    return (

      <div className={`${this.props.required ? "input-required" : ""}`}>
        {this.props.label && (
          <React.Fragment>
            <Label for={this.props.name} className='label-nowrap' title={this.props.label}>
              {this.props.label}
              {this.props.required && "*"}
            </Label>{" "}
          </React.Fragment>
        )}
        {this.props.helpText && (
          <React.Fragment>
            <i className="icon-duvida" id={"help_" + this.props.id} />
            <ToolTipHelp
              target={"help_" + this.props.id}
              message={this.props.helpText}
            />
          </React.Fragment>
        )}
        <DatePicker
          disabled={this.props.disabled}
          id={this.props.id}
          name={this.props.name}
          className={`form-control ${messageValidate ? 'is-invalid' : ''}`}
          selected={this.getValue()}
          onChange={this.handleChange}
          required={this.props.required}
          locale="pt-BR"
          style={{ borderColor: "red" }}
          placeholderText={this.props.placeholder}
          onBlur={this.props.handleOnBlur}

          popperPlacement={this.props.popperPlacement}
          maxDate={this.props.maxDate ? moment(this.props.maxDate) : null}
          minDate={this.props.minDate ? moment(this.props.minDate) : null}
          showTimeSelect={true}
          timeIntervals={this.props.timeIntervals ? this.props.timeIntervals : 15}
          timeFormat="HH:mm"
          timeCaption="Horas"

          dateFormat={this.getFormat()}
          showTimeSelectOnly={false}

        />
        {messageValidate && (
          <ToolTipFeedBack
            target={this.props.id}
            messageValidate={messageValidate}
          />
        )}
      </div>
    );
  }
}
