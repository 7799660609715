//@flow
import React from 'react';
import { toastr } from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import IconErrorToast from '../components/ToastIcons/IconErrorToast';
import IconSucessToast from '../components/ToastIcons/IconSucessToast';

export default {
  state: {},
  effects: () => ({
    success: payload => {
      toastr.success(
        '',
        (payload.code ? payload.code : '') + ' ' + payload.message
      );
    },
    error: payload => {
      toastr.error(
        '',
        (payload.code ? payload.code : '') + ' ' + payload.message
      );
    },
    clear: () => {
      toastr.clean();
    },
    info: async payload => {
      toastr.info(
        '',
        (payload.code ? payload.code : '') + ' ' + payload.message
      );
    },
    warning: async payload => {
      toastr.info(
        '',
        (payload.code ? payload.code : '') + ' ' + payload.message,
          {
            icon: <IconErrorToast />,
            timeOut: 1000000,
            progressBar:false

          }
      );
    }
  })
};
