import * as React from "react";
import {produtoService} from "../../services/produto.service";
import _ from "../pedidos/PedidoEdit";
import FormSelectInput from "../../components/Inputs/FormSelectInput";


export default class DadosNota extends React.Component {

    componentDidMount() {
        const {infNFe} = this.props;
        this.props.loading(true)
        produtoService.findAllByCnpjAndCodigo(infNFe.det,infNFe.emit.cnpj).then(r=>{
         //   this.props.setProduto(r.data)
            this.props.loading(false)
            console.log(123)
            console.log(r);
            this.props.setFieldValue('produtos',r)
        });



    }

    render() {
        const {infNFe,values} = this.props;
        console.log(values)
        return (
            <div className={'col-12 m-2 border border-dark p-2'}>
                <h2>Informações da NFe</h2>
                <ul>
                    <li><strong>ID:</strong> {infNFe.ide.cuf}{infNFe.ide.nnf}</li>
                    <li><strong>Versão:</strong> {infNFe.ide.versao}</li>
                </ul>

                <h3>Emissor</h3>
                <ul>
                    <li><strong>CNPJ:</strong> {infNFe.emit.cnpj}</li>
                    <li><strong>Nome:</strong> {infNFe.emit.xnome}</li>
                    <li>
                        <strong>Endereço:</strong> {infNFe.emit.enderEmit.xlgr}, {infNFe.emit.enderEmit.nro} - {infNFe.emit.enderEmit.xbairro} - {infNFe.emit.enderEmit.xmun}/{infNFe.emit.enderEmit.uf} -
                        CEP: {infNFe.emit.enderEmit.cep}
                    </li>
                </ul>

                <h3>Destinatário</h3>
                <ul>
                    <li><strong>CPF:</strong> {infNFe.dest.cpf || infNFe.dest.cnpj}</li>
                    <li><strong>Nome:</strong> {infNFe.dest.xnome}</li>
                    <li>
                        <strong>Endereço:</strong> {infNFe.dest.enderDest.xlgr}, {infNFe.dest.enderDest.nro} - {infNFe.dest.enderDest.xbairro} - {infNFe.dest.enderDest.xmun}/{infNFe.dest.enderDest.uf} -
                        CEP: {infNFe.dest.enderDest.cep}
                    </li>
                </ul>

                <h3>Detalhes da Nota</h3>
                <table>
                    <thead>
                    <tr className={"row"}>
                        <th className={"col-1"}>Item</th>
                        <th className={"col-2"}>Código</th>
                        <th className={"col-3"}>Produto</th>
                        <th className={"col-3"}>Descrição</th>
                        <th className={"col-1"}>Quantidade</th>
                        <th className={"col-1"}>Valor Unitário</th>
                        <th className={"col-1"}>Valor Total</th>

                    </tr>
                    </thead>
                    <tbody>
                    {infNFe.det.map((item,i) => (
                        <tr  className={"row"} key={item.nItem}>
                            <td  className={"col-1"}>{item.nItem}</td>
                            <td  className={"col-2"}>{item.prod.cprod}</td>
                            <td  className={"col-3"}>

                                <FormSelectInput
                                    name={`produtos[${i}]`}
                                    id={'produto'}
                                    label="Código do Produto"
                                    placeholder="Selecione o produto"
                                    valueKey="id"
                                    labelKey="codigoProduto"


                                    noSize={true}
                                    required={false}
                                    returnFullObject={true}
                                    isMulti={false}
                                    searchable={false}
                                    allowSelectAll={false}
                                    service={produtoService.findAll}
                                    onChange={(name,value)=>{
                                        this.props.setFieldValue(name,value)
                                    }}
                                   value={values.produtos&&values.produtos.length>i&&values.produtos[i]}
                                  //  erroMensagem={_.get(errors,`itens[${index}].produto.id`)}
                                />



                            </td>
                            <td  className={"col-3"}>{item.prod.xprod}</td>
                            <td  className={"col-1"}>{item.prod.qcom}</td>
                            <td  className={"col-1"}>R$ {item.prod.vunTrib}</td>
                            <td  className={"col-1"}>R$ {item.prod.vprod}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>

                <h3>Total da Nota</h3>
                <ul>
                    <li><strong>Valor Total:</strong> R$ {infNFe.total.icmstot.vnf}</li>
                    <li><strong>Valor do ICMS:</strong> R$ {infNFe.total.icmstot.vicms}</li>
                    <li><strong>Valor do ST:</strong> R$ {infNFe.total.icmstot.vst}</li>
                </ul>
            </div>
        );
    }
}