import {urlsConstants} from '../constants/url.constant';
import {defaultService} from './defaultService';


export const notasService = {

    importarNota,
    findByFiltro,
    save
};

function save(nota){
    return defaultService.doPost(`${urlsConstants.API_NFS}save`,nota)
}
function importarNota(nota){
    return defaultService.doPost(`${urlsConstants.API_NFS}`,nota)
}

function findEstoque(produto){
    return defaultService.doGet(`${urlsConstants.API_PEDIDOS}getEstoque/${produto.codigoOmni}`)
}

function findAll(){
    return defaultService.doGet(`${urlsConstants.API_PEDIDOS}findAll`)
}
function nextNumber(){
    return defaultService.doGet(`${urlsConstants.API_PEDIDOS}next`)
}
function findByFiltro(
    options: Options,
    filter: any
): Promise<Array<>> {
    if(filter&&filter.cliente) {
        return defaultService.doGet(
            `${urlsConstants.PEDIDOS}/search/findByCliente?page=${options.page - 1}&cliente=${filter.cliente.id}`,
            options,
            filter
        );
    }
    return defaultService.doGet(
        `${urlsConstants.PEDIDOS}?page=${options.page-1}`,
        options,
        filter
    );
}

function doSave(usuario: usuario) {
    return defaultService.doSave(urlsConstants.PEDIDOS, usuario);
}
function doGet(id) {
    return defaultService.doGet(`${urlsConstants.PEDIDOS}/${id}`);
}