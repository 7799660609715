//@flow
import * as React from 'react';

import {Route, Switch} from 'react-router-dom';
import NotasCrudList from "./NotasCrudList";
import NotasEdit from "./NotasEdit";

export default class NotasCrud extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
          <Switch>
              <Route
                  path="/notas"
                  exact
                  component={NotasEdit}
              />
          <Route
            path="/notas/list"
            exact
            component={NotasCrudList}
          />



      <Route
      path="/notas/edit/:id"

      component={NotasEdit}
      />
        </Switch>
      </React.Fragment>
    );
  }
}
