import React, { Component } from "react";
import debounce from "lodash.debounce";

export class InputFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
        };
        this.callService = debounce(this.callService.bind(this), 1000);
    }

    handleChange(event) {
        const { value } = event.target;
        this.setState({ value });
        this.callService(value);
    }

    callService(value) {
        this.props.service(value);
    }

    render() {
        const { value } = this.state;
        const { service, ...rest } = this.props;
        return (
            <input
                {...rest}
                value={value}
                onChange={this.handleChange.bind(this)}
            />
        );
    }
}
