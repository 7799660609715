//@flow
import React from 'react';
import { dateHelper } from './date.helper';
import { propertyConstantes } from '../constants/property.constantes';
import _ from 'lodash';
import { objectsConstants } from '../constants/objects.constants';

export const ordemServicoHelper = {
  getStatus,
  getListStatus,
  getListStatusTrocaAdmin,
  getFarol,
  getObjectsInArray,
  formatMoney,
  getClientesTipo,
  getListStatusFinalizada,
  getFarolHelper,

};

function formatMoney(n, c, d, t) {
  var c = isNaN((c = Math.abs(c))) ? 2 : c,
    d = d == undefined ? '.' : d,
    t = t == undefined ? ',' : t,
    s = n < 0 ? '-' : '',
    i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c)))),
    j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    'R$ ' +
    s +
    (j ? i.substr(0, j) + t : '') +
    i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) +
    (c
      ? d +
      Math.abs(n - i)
        .toFixed(c)
        .slice(2)
      : '')
  );
}


async function getObjectsInArray(arrayItens, name) {
  if (arrayItens.length) {
    let newArrayItens = await Promise.all(
      _.map(arrayItens, async function (item) {
        return await _.get(item, name);
      })
    );
    return _.without(newArrayItens, undefined);
  } else {
    return [];
  }
}

// - Branco - Os, reparada entregue para cliente
// - Branco - Os, reparada entregue para cliente
// - AZUL - até 7 dias;
// - VERDE - 8 a 17 dias;
// - AMARELO -18 a 25;
// - VERMELHO - acima de 25;

function getFarol(ordemServico) {
  let dias = ordemServico.diasCorridos ? ordemServico.diasCorridos : dateHelper.diff(ordemServico.creationDateTime, ordemServico.dataReparo);
  if (
    (ordemServico.diasCorridos && ordemServico.diasCorridos == -1) ||
    ordemServico.dataReparo ||
    ordemServico.dataEntragaRelogio ||
    ordemServico.statusOS.name === propertyConstantes.ORCAMENTO_REPROVADO ||
    ordemServico.statusOS.name === propertyConstantes.ATENDIDA
  ) {
    return 'farol-branco';
  }

  if (dias <= 7) {
    return 'farol-azul';
  } else if (dias > 7 && dias <= 17) {
    return 'farol-verde';
  } else if (dias > 18 && dias <= 25) {
    return 'farol-amarelo';
  } else {
    return 'farol-vermelho';
  }
}
function getFarolHelper(ordemServico) {
  let dias = ordemServico.diasCorridos
    ? ordemServico.diasCorridos
    : dateHelper.diff(ordemServico.creationDateTime, ordemServico.dataReparo);
  if (
    (ordemServico.diasCorridos && ordemServico.diasCorridos == -1) ||
    ordemServico.dataReparo ||
    ordemServico.dataEntragaRelogio ||
    ordemServico.statusOS.name === propertyConstantes.ORCAMENTO_REPROVADO ||
    ordemServico.statusOS.name === propertyConstantes.ATENDIDA
  ) {
    return 'Os, reparada entregue para cliente';
  }

  if (dias <= 7) {
    return 'Os abertas em até 7 dias';
  } else if (dias > 7 && dias <= 17) {
    return 'Os abertas entre 8 a 17 dias';
  } else if (dias > 18 && dias <= 25) {
    return 'Os abertas entre 18 a 25 dias';
  } else {
    return 'Os abertas acima de 25 dias';
  }
}
function getListStatus() {
  return [
    { id: propertyConstantes.ABERTURA_DE_OS, nome: 'Abertura de OS' },
    { id: propertyConstantes.AVALIACAO_TECNICA, nome: 'Avaliação técnica' },
    { id: propertyConstantes.APROVAR_ORCAMENTO, nome: 'Aprovar Orçamento' },
    { id: propertyConstantes.ORCAMENTO_REPROVADO, nome: 'Orçamento reprovado' },
    { id: propertyConstantes.AGUARDANDO_CONSERTO, nome: 'Aguardando conserto' },
    { id: propertyConstantes.CONSERTADA, nome: 'Consertada' },
    { id: propertyConstantes.FINALIZADA, nome: 'Finalizada' },
    { id: propertyConstantes.ENTREGUE, nome: 'Entregue' },
    { id: propertyConstantes.ORCAMENTO_APROVADO, nome: 'Orçamento aprovado' },
    { id: propertyConstantes.PRE_AVALIACAO_TECNICA, nome: 'Pré avaliação técnica' },
    { id: propertyConstantes.CONTROLE_QUALIDADE, nome: 'Controle qualidade' },
    { id: propertyConstantes.APROVADO_QC, nome: 'Aprovado QC.' },
    { id: propertyConstantes.REPROVADO_QC, nome: 'Reprovado QC.' },
    { id: 'TODOS', nome: 'Todos' }
  ];
}
function getListStatusTrocaAdmin() {
  return [
    {
      id: propertyConstantes.ESCOLHA_DE_MODELO,
      nome: 'Troca aguardando aprovação'
    },
    { id: propertyConstantes.TROCA_MODELO, nome: 'Troca de modelo' },
    { id: propertyConstantes.TROCA_MAIS_MODELO, nome: 'Troca mais modelos' },
    { id: 'TODOS', nome: 'Todos' }
  ];
}
function getStatus(statusEnum: string): string {
  if ('EM_CRIACAO' === statusEnum) {
    return 'Em criação';
  }
  return statusEnum;
}

function getClientesTipo() {
  return objectsConstants.TIPO_PESSOA_ARRAY;
}
function getListStatusFinalizada() {
  return [
    { id: propertyConstantes.FINALIZADA, nome: 'Finalizada' },
    { id: propertyConstantes.REPARADA, nome: 'Reparada' },
    { id: 'TODOS', nome: 'Todos' }
  ];
}
