import * as React from "react";
import { Formik } from 'formik';
import _ from 'lodash';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import FormSelectInput from '../../components/Inputs/FormSelectInput';
import SelectInput from '../../components/Inputs/SelectInput';
import Toast from '../../components/Toasts/Toast';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import {userService} from "../../services/user.service";
import FormInput from "../../components/Inputs/FormInput";
import CpfCnpjInput from "../../components/Inputs/CpfCnpjInput";
import {defaultService} from "../../services/defaultService";
import {objectsConstants} from "../../constants/objects.constants";
import {produtoService} from "../../services/produto.service"
import {configuracoesService} from "../../services/configuracoes.service";
import {pedidosService} from "../../services/pedidos.service";
import moment from 'moment';
;
class ConfiguracaoEdit extends React.PureComponent<Props> {

    constructor(props){
        super(props)
        this.state={entity:{}};
    }

    componentDidMount() {
configuracoesService.findFirst().then(r=>{
   this.setState({entity:r.data})
})
    }

    showError = messageError => {
        this.props.error({ message: messageError });
        return '';
    };

    showInfo = messageInfo => {
        this.props.info({ message: messageInfo });
        return '';
    };

    clearError = () => {
        this.props.clear();
        return '';
    };
    render() {


        return (
            <React.Fragment>
                <TopoTitleComponente mainTitle="Configuracões" subTitle=" " canBack={true} />
                <Formik
                    validationSchema={{}}
                    validateOnBlur={false}
                    validateOnChange={false}
                    initialValues={this.state.entity}
                    enableReinitialize={true}
                    onSubmit={(values, actions) => {
                        let insertable = values.id === null || values.id === undefined;
                        let clientEntity = values;

                       configuracoesService
                            .doSave(clientEntity)
                            .then(response => {
                                console.log(response)
                                let id = defaultService.getIdFromUrl(response.data._links.self.href);
                                values.id = id;



                                actions.setSubmitting(false);
                                this.clearError();
                                this.props.success({ message: 'Configurações salva com sucesso.' });
                            })
                            .catch((e) => {

                                console.log(e)
                                console.log('brasil22222');
                                actions.setSubmitting(false);
                            });
                    }}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleSubmit,
                          isSubmitting,
                          setFieldValue
                      }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <div className="flex-column p-5">
                                <Toast />

                                {!_.isEmpty(errors) &&
                                this.showError(objectsConstants.messageCampoObrigatorio)}

                                {values.id && this.clearError()}
                                <div className="cardSeculus d-flex flex-column mb-0">
                                    <div className="topoCard d-flex flex-column flex-sm-row">
                                        <h3 className="flex-grow-1">Configurações</h3>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-3 col-lg-3">
                                            <label>Tempo para Separação de pedido*</label>
                                            <input
                                                type="number"
                                                className={`form-control ${errors.email &&
                                                touched.email &&
                                                'error'}`}
                                                name={`tempoSeparacao`}
                                                placeholder="Digite o tempo de separação em horas"
                                                value={values.tempoSeparacao}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group col-3 col-lg-3">
                                            <label>Tempo para Faturar*</label>
                                            <input
                                                type="number"
                                                className={`form-control ${errors.email &&
                                                touched.email &&
                                                'error'}`}
                                                name={`tempoFaturar`}
                                                placeholder="Digite o tempo de Faturamento"
                                                value={values.tempoFaturar}
                                                onChange={handleChange}
                                            />
                                        </div>










                                    </div>

                                    <div className="d-flex flex-row flex-sm-row ">
                                        <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
                                            <button
                                                type="button"
                                                className="btn btn-secondary btn30 white"
                                                disabled={isSubmitting}
                                                onClick={() => this.props.history.goBack()}
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                        <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
                                            <button
                                                type="submit"
                                                className="btn btn-secondary btn30 green"
                                                disabled={isSubmitting}
                                            >
                                                Salvar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}


function mapStateToProps(state) {
    const { user } = state.authentication;

    return {
        user,

    };
}

const mapDispatch = ({
                         alert: { success, error, clear },
                         load: { loading },
                     }) => ({
    success: (msg) => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: (msg) => error(msg),
    clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
    forwardRef: true,
})(ConfiguracaoEdit)