//flow
import axios from 'axios/index';
import React, {PureComponent} from 'react';
import ReactLoading from 'react-loading';
import {connect} from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {Modal, ModalBody} from 'reactstrap';
import {alertActions} from './actions/alert.action';
import {PrivateRoute} from './components/templates/PrivateRoute';
import IconErrorToast from './components/ToastIcons/IconErrorToast';
import {objectsConstants} from './constants/objects.constants';
import {translate} from './helpers/message.helper';
import {authenticationService} from './services/authentication.service';
import {userService} from './services/user.service';
import IndexContent from './views/index/IndexContent';
import Main from './views/Main/Main';
import ClienteCrud from "./views/clientes/ClienteCrud";
import Toast from "./components/Toasts/Toast";
import { urlsConstants } from './constants/url.constant';

import UsuarioCrud from "./views/usuarios/UsuarioCrud";
type Props = {
  dispatch: any,
  loggedIn: boolean,
  loading: boolean
};

class App extends PureComponent<Props, State> {
  componentDidMount() {
    if (authenticationService.hasToken()) {
      userService
        .me()
        .then(response => this.props.successLogin({ user: response.data }));
    }
  }

  handleSignUp = () => {
    alert('CADASTRAR');
  };

  handleToastr = () => {
    const toastrOptionsSuccess = { icon: <IconErrorToast /> };
    alertActions.error(
      '',
      objectsConstants.messageCampoObrigatorio,
      toastrOptionsSuccess
    );
    alertActions.success(objectsConstants.messageCampoObrigatorio, '');
  };

  initAxios = (props: any) => {
    axios.interceptors.response.use(
      function (response) {
        props.clearMessage();
        if (response && response.data && response.data.messages) {
          for (let i = 0; i < response.data.messages.length; i++) {
            const message = response.data.messages[i];
            console.log(message);
            if (message.type == 'WARNING') {
              console.log('entrou warning' + '');
              props.info(translate(message.message));
            }
          }
        }
        return response;
      },
      err => {
        let res = err.response;

        if (
          res &&
          res.status === 401 &&
          res.config &&
          !res.config.__isRetryRequest &&
          res.data.error === 'invalid_token'
        ) {
          console.log('Erro de token');
          this.props.logoff();
          this.props.error({ message: "Sessão expirada" });

        }
        if (res && res.status === 400) {
          props.addAllMessage(res.data.messages);
        }
        return Promise.reject(err);
      }
    );
  };
  render() {
    const { loggedIn, loading } = this.props;
    this.initAxios(this.props);
    return (
      <React.Fragment>
        <Toast />
        <Modal
          isOpen={loading}
          toggle={this.toggle}
          backdrop="static"
          className="modalLoading modal-dialog-centered"
        >
          <ModalBody>
            {' '}
            <ReactLoading
              className="loading"
              type={'bars'}
              color="#fff"
              height={'70px'}
              width={'70px'}
            />
          </ModalBody>
        </Modal>

        <BrowserRouter basename={urlsConstants.CONTEXT_PATH}>
          <Switch>

            <Route path="/index" component={IndexContent} />


            <PrivateRoute path="/" component={Main} loggedIn={loggedIn} />
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.authentication;
  const { load } = state.load;

  return {
    loggedIn,
    loading: load
  };
}
const mapDispatch = ({
  fieldMessage: { addAllMessage, clearMessage },
  authentication: { successLogin, logoff },
  load: { loading },
  alert: { error, clear, info }
}) => ({
  addAllMessage: messages => addAllMessage(messages),
  successLogin: user => successLogin(user),
  clearMessage: () => clearMessage(),
  error: message => error(message),
  info: message => info({ message }),
  clear: clear(),
  logoff: () => logoff(),
});
export default connect(
  mapStateToProps,
  mapDispatch
)(App);
