//@flow
export default {
  state: { filter: {}, page: null, currentPage: null },
  reducers: {
    changeFilter: (state, payload) => {
      console.log(payload);
      return Object.assign({}, state, {
        filter: payload.filter,
        page: payload.page,
        currentPage: payload.currentPage
      });
    },
    clearFilter: (state, payload) => {
      return Object.assign({}, state, {
        filter: {}
      });
    }
  }
};
