//@flow

export default {
  state: { totalMessages: 0 },
  reducers: {
    addTotalMessagesOs: (state, payload) => {
      return Object.assign({}, state, {
        totalMessages: payload
      });
    }
  }
};
