//@flow
import _ from 'lodash';
import * as React from 'react';
import {TableHeaderColumn} from 'react-bootstrap-table';
import connect from 'react-redux/es/connect/connect';
import ListagemPage from '../../components/templates/ListagemPage';
import PedidosCrudListFilter from './PedidosCrudListFilter';
import {pedidosService} from '../../services/pedidos.service';
import TopoTitleComponente from "../Topo/TopoTitleComponente";
import {defaultService} from "../../services/defaultService";
import {tableHelper} from '../../helpers/tableHelper';
const columns = [
  {
    path: 'numeroPedido',
    title: 'Número do pedido',
    isKey: true,
    dataSort: false,
    className: 'txtBold'
  },
  {
    path: 'cliente.nomeFantasia',
    title: 'Cliente',
    isKey: false,
    dataSort: false,

  },
  {
    path: 'statusAlcis',
    title: 'Status WMS',
    isKey: false,
    dataSort: false,

  },


];

const endPoint = '/pedidos/novo/';
const endPointView = '/pedidos/edit/';
class PedidosCrudList extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props);
  }

  handleRowClick = (row: e) => {

   let id = this.props.user.cliente? defaultService.getIdFromUrl(row._links.self.href):row.id
    this.props.history.push(endPointView + id);
  };

  handleNewButton = (row: e) => {
    this.props.history.push(endPoint);
    this.props.history.push({
      pathname: endPoint,
      state: {
        titlePage: 'Novo pedido'
      }
    });
  };
  render() {
    let fields = _.map(columns, function (coluna) {
      return coluna.path;
    });
    const tableColumn = _.map(columns, (column, index) => (
      <TableHeaderColumn
        key={index}
        dataField={column.path}
        dataFormat={tableHelper.renderCustom}
        formatExtraData={column}
        isKey={column.isKey}
        dataSort={column.dataSort}
        hidden={column.hidden}
      >
        {column.title}
      </TableHeaderColumn>
    ));
console.log(123);
    return (
      <React.Fragment>
        <TopoTitleComponente mainTitle="Pedidos" subTitle=" " canBack={false} />
        <ListagemPage
          title={`Pedidos`}
          newButtonActionClick={this.handleNewButton}
          delete={() => { }}
          service={pedidosService}
          showNewButton={true}
          labelNewButton="Novo Pedido"
          defaultOrder={'nome'}
          defaultDirection={'asc'}
          defaultFilter={{cliente:this.props.user.cliente}}
    onRowClick={

      this.handleRowClick
    }
          FormFilter={ PedidosCrudListFilter}
          controller={this.props.user.cliente?'pedidos':null}
          fields={fields}
    array={'pedidos'}
        >
          {tableColumn}
        </ListagemPage>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user
  };
}

export default connect(mapStateToProps)(PedidosCrudList);
