//@flow
import * as React from 'react';

import {Route, Switch} from 'react-router-dom';
import UsuarioCrudList from "./UsuarioCrudList";

import UsuarioEdit from "./UsuarioEdit";
export default class UsuarioCrud extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/usuario"
            exact
            component={UsuarioCrudList}
          />
          <Route
      path="/usuario/novo"
      exact
      component={UsuarioEdit}
      />


      <Route
      path="/usuario/:id"

      component={UsuarioEdit}
      />

        </Switch>
      </React.Fragment>
    );
  }
}