//@flow
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import App from './App';
import './App.scss';
import 'bootstrap';
import * as serviceWorker from './serviceWorker';
import 'react-select/dist/react-select.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-bootstrap-table/css/react-bootstrap-table.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'cropperjs/dist/cropper.css';
import 'react-month-picker/css/month-picker.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../node_modules/slick-carousel/slick/slick.css';
import '../node_modules/slick-carousel/slick/slick-theme.css';

const root = document.getElementById('root');
String.prototype.toProperCase = function () {
  return this.toLowerCase().replace(/(^[a-z]| [a-z]|-[a-z])/g, function ($1) {
    return $1.toUpperCase();
  });
};

if (root !== null) {
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    root
  );
}
serviceWorker.unregister();
