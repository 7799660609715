import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import moment from 'moment';
import {auditLogService} from "../../services/audit.service";

class AuditLogModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logs: [],
            isModalOpen: false,
        };
    }

    openModal = () => {
        auditLogService.getAuditLogsByEntityId(this.props.pedidoId)  // Use the service to fetch logs
            .then(response => {
                this.setState({ logs: response, isModalOpen: true });
            })
            .catch(error => {
                console.error("Failed to fetch logs:", error);
                // You might want to display some error message or handle this in some way
            });
    }

    closeModal = () => {
        this.setState({ isModalOpen: false });
    }

    formatDate = (timestamp) => {
        return moment(timestamp).format('DD/MM/YYYY HH:mm:ss');
    }

    render() {
        return (
            <React.Fragment>
                <button type="button" className="btn btn-primary" onClick={this.openModal}>Ver histórico</button>
                <Modal size="lg" isOpen={this.state.isModalOpen} toggle={this.closeModal} className={'modal-pedido'}>
                    <div className="modal-header no-border justify-content-left">
                        <div className="mt-2 w-100">
                            <div className="w-100 d-flex justify-content-end ">
                                <button
                                    type="submit"
                                    className="btn btn-light mx-1"
                                    onClick={this.closeModal}
                                >
                                    Fechar
                                </button>
                            </div>
                            <p><b>Histórico de Auditoria do Pedido</b></p>
                        </div>
                    </div>

                    <ModalBody>
                        {this.state.logs.map(log => (
                            <div key={log.id} style={{ marginBottom: '15px' }}>
                                <b  style={{ fontSize: '1.2rem' }} >Alterado por: {log.usuario}</b><br/>
                                <span style={{ fontSize: '0.7rem' }}>{this.formatDate(log.timestamp)}</span>
                                <br/>
                                <br/>

                                <ul>
                                    {log.changes.map((change, index) => (
                                        <li key={index}>
                                            {change}
                                        </li>
                                    ))}
                                </ul>
                                <hr/> {/* Separador */}
                            </div>
                        ))}
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default AuditLogModal;
