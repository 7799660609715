import * as React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {Menu} from '../../components/menu/Menu';
import Toast from '../../components/Toasts/Toast';
import Topo from '../Topo/Topo';
import ClienteCrud from "../clientes/ClienteCrud";
import IndexContent from "../index/IndexContent";
import UsuarioCrud from "../usuarios/UsuarioCrud";
import PedidosCrud from "../pedidos/PedidosCrud";
import Dashboard from "../dashboard/Dashboard"
import ConfiguracaoEdit from "../configuracao/ConfiguracaoEdit";
import connect from "react-redux/es/connect/connect";
import NotasCrud from "../notas/NotasCrud";
type Props = {};
type State = {
  isModalVisible: boolean
};
class Main extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalVisible: false
    };
    this._toggleModal = this._toggleModal.bind(this);
  }

  _toggleModal = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };
  render() {
    return (
      <div>
        <Menu />
        <Topo />
        <Toast />
        <div className="content-wrap d-flex justify-content-center flex-column flex-sm-row">
          <Switch>
              {!this.props.user.cliente&&(
                  <React.Fragment>
        <Route path="/cliente" component={ ClienteCrud} />
        <Route path="/dashboard" exact component={ Dashboard} />

        <Route path="/usuario" component={ UsuarioCrud} />
              <Route path="/configuracoes/view/" component={ ConfiguracaoEdit} />
                      <Route path="/pedidos" component={ PedidosCrud} />
                      <Route path="/notas" component={ NotasCrud} />
                  </React.Fragment>
                  )}

              <Route path="/pedidos" component={ PedidosCrud} />

          </Switch>
        </div>
        <footer>
          <div className="mt-auto rodape">
              {(new Date().getFullYear())} © biotechlogistica. - <a href="http://www.biotechlogistica.com.br" rel="noopener noreferrer" target="_blank">Code 4 Sistemas</a>
          </div>
        </footer>
      </div>
    );
  }
}
function mapStateToProps(state) {
    const {user, permissions} = state.authentication;



    return {
        user,
        permissions

    };
}

 export default withRouter(
    connect(
        mapStateToProps

    )(Main)
);

