//@flow
import React, {PureComponent} from "react";
import {Modal, ModalBody} from "reactstrap";
import iconFechar from "../../img/icon-fechar.svg";
import * as Yup from "yup";
import connect from "react-redux/lib/connect/connect";
import {withRouter} from "react-router-dom";
import FormSelectInput from "../Inputs/FormSelectInput";
import {objectsConstants} from "../../constants/objects.constants";
import SelectInput from "../Inputs/SelectInput";
import {pedidosService} from "../../services/pedidos.service";
import _ from 'lodash';

const ValidateForm = Yup.object().shape({
  tipoSaida: Yup.string().required('Obrigatório'),
});

class ChangeStatusModal extends PureComponent<Props> {

  constructor(props) {
    super(props);

    this.state = {
      isModalVisible: false,
    };
  }

  salvar=()=> {
   console.log(this.props.pedido);
   let pedido = _.cloneDeep(this.props.pedido);
   pedido.status = this.state.status.name;
   pedidosService.doSave(pedido).then(()=>{
     this.props.toogleModal();
   });

  }

  render() {
    let { onSaved } = this.props;
    let _self = this;

    return (
      <Modal
        size="lg"
        isOpen={this.props.isModalVisible}
        className="customModal modalOrcamento"
        toggle={this.props.toogleModal}
      >
        <div className="modal-header no-border justify-content-left">
          {/* <img src={iconChecklist} className="float-left mr-3" alt="icone"></img> */}
          <div className="mt-2 w-100">
            <div className="w-100 d-flex justify-content-end ">
              <button
                  type="submit"
                  className="btn btn-light mx-1"
                  disabled={false}
                  onClick={this.props.toogleModal}
              >
                Cancelar
              </button>
              <button
                  type="button"
                  className="btn btn-primary mx-1"
                  disabled={!this.state.status||!this.state.status.name}
                  onClick={this.salvar}
              >
                Salvar
              </button>

            </div>
            <p><b>Selecione o novo status do pedido</b></p>
          </div>
          {/* <img src={iconFechar} className="pointer ml-auto" onClick={this.toggleModal} alt="fechar"></img> */}
        </div>

        <ModalBody>
          <div className="container">
            <div className="row">
              <div className="col d-flex flex-column align-items-center corpo-modal">

                <SelectInput
                    name="status"
                    id={'status'}
                    label="Status"
                    placeholder="Selecione o novo status do pedido"
                    style={'col-6'}
                    valueKey="name"
                    labelKey="description"
                    noSize={true}
                    required={false}
                    returnFullObject={true}
                    isMulti={false}
                    searchable={false}
                    allowSelectAll={false}
                      options={objectsConstants.STATUS}
                    onChange={(name,value)=>{
                      this.setState({status:value})
                    }}
                    value={this.state.status}

                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}


export default connect( null,
    null,
    null,
    { withRef: true,forwardRef:true })(
    withRouter(ChangeStatusModal)
);
