//@flow
import * as React from 'react';

import {Route, Switch} from 'react-router-dom';
import PedidosCrudList from './PedidosCrudList';
import PedidoEdit from './PedidoEdit';

export default class PedidosCrud extends React.PureComponent<{}> {
  render() {
    return (
      <React.Fragment>
        <Switch>
          <Route
            path="/pedidos"
            exact
            component={PedidosCrudList}
          />
          <Route
      path="/pedidos/novo"
      exact
      component={PedidoEdit}
      />


      <Route
      path="/pedidos/edit/:id"

      component={PedidoEdit}
      />
        </Switch>
      </React.Fragment>
    );
  }
}
