import * as React from "react";
import { Formik } from 'formik';
import _ from 'lodash';
import 'react-confirm-alert/src/react-confirm-alert.css';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import FormSelectInput from '../../components/Inputs/FormSelectInput';
import SelectInput from '../../components/Inputs/SelectInput';
import Toast from '../../components/Toasts/Toast';
import TopoTitleComponente from '../Topo/TopoTitleComponente';
import {userService} from "../../services/user.service";
import FormInput from "../../components/Inputs/FormInput";
import CpfCnpjInput from "../../components/Inputs/CpfCnpjInput";
import {defaultService} from "../../services/defaultService";
import {objectsConstants} from "../../constants/objects.constants";
import {clienteService} from "../../services/cliente.service";
class UsuarioEdit extends React.PureComponent<Props> {

constructor(props){
    super(props)
    this.state={entity:{}};
}

    componentDidMount() {
        console.log(this.props)
        if (this.props.match.params.id) {
            //this.props.loading(true);
            userService.doGet(this.props.match.params.id).then(
                (response) => {
                    console.log(response);
                    let usuario = response.data;
                    console.log(usuario);
                    usuario.senha =  null;
                    this.setState({ entity: usuario }, () => {
                        //this.props.loading(false);
                    });
                },
                (error) => {
                    console.log('error');
                    console.log(error);
                    this.props.loading(false);
                }
            );
        }
    }

    render() {
        const { entity } = this.state;
        console.log(1234)
        return (
            <React.Fragment>
            <TopoTitleComponente mainTitle="Cliente" subTitle=" " canBack={true} />
        <Formik
        validationSchema={{}}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={entity}
        enableReinitialize={true}
        onSubmit={(values, actions) => {
            let insertable = values.id === null || values.id === undefined;
            let clientEntity = values;
            if(_.get(clientEntity,'_links.self.href')){
                _.set(clientEntity,'id',defaultService.getIdFromUrl(_.get(clientEntity,'_links.self.href')))
            }
            _.set(clientEntity, 'authorities',null);
           userService
                .doSave(clientEntity)
                .then(response => {
                    console.log(response)
                    let id = defaultService.getIdFromUrl(response.data._links.self.href);
                    values.id = id;

                    console.log('brasil');
                        this.props.history.push('/usuario');

                    actions.setSubmitting(false);
                    this.clearError();
                    this.props.success({ message: 'Usuario salvo com sucesso.' });
                })
                .catch((e) => {

                    console.log(e)
                    console.log('brasil22222');
                    actions.setSubmitting(false);
                });
        }}
    >
        {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue
          }) => (
            <form onSubmit={handleSubmit} noValidate>
        <div className="flex-column">
            <Toast />

            {!_.isEmpty(errors) &&
        this.showError(objectsConstants.messageCampoObrigatorio)}

            {values.id && this.clearError()}
        <div className="cardSeculus d-flex flex-column mb-0">
            <div className="topoCard d-flex flex-column flex-sm-row">
            <h3 className="flex-grow-1">Cliente</h3>
            </div>

            <div className="form-row">
            <div className="form-group col-12 col-lg-12">
            <label>Nome*</label>
            <input
            type="text"
            className={`form-control ${errors.nome &&
            touched.nome &&
            'error'}`}
            name="nome"
            placeholder="Digite o nome completo ou nome fantásia"
            value={values.nome}
            onChange={e => {
            const { name, value } = e.currentTarget;
            if (value) {
                setFieldValue(name, value.toProperCase());
            } else {
                setFieldValue(name, value);
            }
        }}
            />
            </div>

            <div className="form-group col-12 col-lg-12">

        <p role="button">

            <CpfCnpjInput
                placeholder="Digite o CPF ou CNPJ"
                erroMensagem={errors.cpf}
                id={'cpf'}
                name="cpf"
                value={values.cpf}
                onChange={handleChange}
                noSize={true}
                label={'CPF'}
                />

        </p>
                <FormSelectInput
            name="cliente"
            id={'cliente'}
            label="Cliente"
            placeholder="Selecione o cliente"
            valueKey="id"
            labelKey="nomeFantasia"
            noSize={true}
            required={false}
            returnFullObject={true}
            isMulti={false}
            searchable={false}
            allowSelectAll={false}
            service={clienteService.findAll}
            onChange={setFieldValue}
            value={values.cliente}

            />
        </div>

        <div className="form-group col-12 col-lg-12 ">
            <label>E-mail </label>
            <input
            type="text"
            className={`form-control ${errors.email &&
            touched.email &&
            'error'}`}
            name="email"
            placeholder="Digite o e-mail"
            value={values.email}
            onChange={handleChange}
            />
            </div>
            <div className="form-group col-12 col-lg-12 ">
            <label>Password </label>
            <input
            type="password"
            className={`form-control ${errors.password &
            touched.password &&
            'error'}`}
            name="senha"
            placeholder="Digite a senha"
            value={values.senha}
            onChange={handleChange}
            />
            </div>

        </div>

        <div className="d-flex flex-row flex-sm-row ">
            <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
            <button
            type="button"
            className="btn btn-secondary btn30 white"
            disabled={isSubmitting}
            onClick={() => this.props.history.goBack()}
        >
            Cancelar
            </button>
            </div>
            <div className="col-6 col-lg text-center p-0 mt-lg-2 mb-1">
            <button
            type="submit"
            className="btn btn-secondary btn30 green"
            disabled={isSubmitting}
                >
                Salvar
                </button>
                </div>
                </div>
                </div>
                </div>
                </form>
        )}
    </Formik>
        </React.Fragment>
    );
    }
}


function mapStateToProps(state) {
    const { user } = state.authentication;

    return {
        user,

    };
}

const mapDispatch = ({
                         alert: { success, error, clear },
                         load: { loading },
                     }) => ({
    success: (msg) => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: (msg) => error(msg),
    clear: () => clear(),
});

export default connect(mapStateToProps, mapDispatch, null, {
    forwardRef: true,
})(UsuarioEdit)